import React from 'react'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'

const FAQ = () => {
  React.useEffect(() => {
    window.scroll(0, 0)
  }, [true])
  return (
    <div>
      <Container maxWidth='md'>
        <div style={{ marginTop: 80 }} className="container">
          <h3>What is Fizio?</h3>
          <span>Fizio is the place where people discover content that inspires them to move.</span><br />
          <h4>How does it work?</h4>
          <p>Trainers create content via blogs, podcasts, and youtube and we syndicate on Fizio.</p>
          <p>Fizio users see this content if it is relevant to their goals and abilities.</p>
          <br />
          <h4>Who is Fizio made for?</h4>
          <p>If you are a human, then it is made for you!</p>
          <p>All humans need to move, and it's our job to make it easy for you to enjoy doing it.</p>
          <br />
          <h4>What type of content is Fizio?</h4>
          <p>We curate content in many modalities on Fizio, that we vet and deem helpful to people in positive and constructive ways.</p>
          <p>We accept all movement focused content regardless of style, language or focus</p>
          <br />
          <h4>Can I be a trainer and make content for Fizio?</h4>
          <p>Yes! Fizio is an open network for trainers to share their knowledge and get paid for it.</p>
          <p>To become a trainer, simply <Link to="/contactus" style={{ color: "#FF4500" }}>apply here</Link> and we will do a quick background check before approving your trainer status.</p>
          <br />
          <h4>Do trainers get paid?</h4>
          <p>Yes! Trainers of Fizio do get paid</p>
          <p>Fizio uses a revenue sharing model to pay trainers based on engagement with their content. Please <Link to="/contactus" style={{ color: "#FF4500" }}>contact us</Link> for specifics.</p>
          <br />
          <h4>Does Fizio have guidelines?</h4>
          <p>Yes, we care deeply about the safety and privacy of our users.</p>
          <p>Our common sense guidelines are available <Link to="/community" style={{ color: "#FF4500" }}>here</Link> and our expectation is that you respect them and take them seriously.</p>
          <br />
          <h4>How can I ask a question about Fizio?</h4>
          <p>Fizio currently provides <Link to="/contactus" style={{ color: "#FF4500" }}>email support</Link> for trainers, customers, and any user of the platform.</p>
          <p>We love interacting with you and hearing your feedback. So don't be shy, hit us up!</p>
          <br />
          <h4>What do I get when I subscribe?</h4>
          <p>When you subscribe to Fizio you get access to everything the platform has to offer.</p>
          <p>You can sign up <Link to="/signup" style={{ color: "#FF4500" }}>here</Link>.</p>
          <br />
          <h4>How can I get started?</h4>
          <p>We offer a <Link to="/signup" style={{ color: "#FF4500" }}>basic signup</Link> for you to experience Fizio for yourself.</p>
          <p>You can pause your subscription at any point if you need a break for any reason.</p>
          <br />
        </div>
      </Container>
    </div>
  )
}

export default FAQ
