import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import SignedInLinks from "../navigation/SignedInLinks"
import SignedOutLinks from "../navigation/SignedOutLinks"

import logo from '../../res/logo.svg'

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Bookmark from '@material-ui/icons/Bookmark';
import Discover from '@material-ui/icons/Language';
import Collection from '@material-ui/icons/Collections'
import Following from '@material-ui/icons/RssFeed';
import CreateCollection from '../collection/CreateCollection'
import CreateGroup from '../groups/CreateGroup'
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Collapse from '@material-ui/core/Collapse';
import SearchBar from '../search/Searchbar'

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

import Hidden from '@material-ui/core/Hidden';


import Router from '../../routes/Router'

import Tour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'


import steps from '../tour/Steps'

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'white',
    boxShadow: '0px 1px rgba(0, 0, 0, 0.12)',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButtonRight: {
    // marginRight: 'auto',
    // marginLeft: 'auto'
  },
  menuButtonLeft: {
    marginRight: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#F8F8F8'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    height: '64px'
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Main = React.memo((props) => {

  const { auth, profile, collections, groups } = props;
  const links = (auth.uid) ? <SignedInLinks profile={profile} /> : <SignedOutLinks />;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isTourOpen, setTourOpen] = useState(false)
  const [customComps, setCustomComps] = useState(false)
  const [checked, setChecked] = React.useState(false);


  const disableBody = target => disableBodyScroll(target)
  const enableBody = target => enableBodyScroll(target)

  let history = useHistory()

  useEffect(() => {

    function keyHandling(e) {
      if (e.keyCode === 75) {
        e.preventDefault()
        setOpen(true)
      }

      if (isTourOpen && e.keyCode === 13) {
        e.preventDefault()
        setCustomComps(!customComps)
      }
    }
    window.addEventListener('keyup', keyHandling)
    return () => window.removeEventListener('keyup', keyHandling)
  }, [isTourOpen, customComps])

  const handleDrawerOpen = () => {
    setOpen(true);
  }

  const handleDrawerClose = () => {
    setOpen(false);
  }

  const renderTour = () => {
    return (
      <div className="" style={{
        width: '100%',
        height: 48,
        background: '#FF4500',
        textAlign: 'center',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <h3 style={{ color: 'white', margin: 20 }}>New to Fizio? Take a quiz to improve your feed.</h3>
        <Button style={{ color: '#FF4500', background: 'white' }}>TAKE QUIZ</Button>
      </div>

    )
  }

  return (
    <div className={classes.root}>
      <Tour
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        steps={auth.uid ? steps.stepsLoggedIn : steps.stepsLoggedOut}
        isOpen={isTourOpen}
        onRequestClose={() => setTourOpen(false)}
        rounded={5}
        accentColor={'#FF4500'}
        lastStepNextButton={<Button style={{ color: '#FF4500', }}>Got it!</Button>}
      />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {/* <IconButton
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButtonLeft, open && classes.hide)}
            data-tut=".third-step"
          >
            <MenuIcon />
          </IconButton> */}
          <Link to="/" className="brand-logo left"><img src={logo} alt='' style={{ height: '70px', margin: 'auto', padding: '0.5rem', paddingLeft: '20px', marginTop: '8px' }} /></Link>

          <div style={{ display: 'flex', margin: 'auto' }}>
            <Hidden smDown>
              <Typography style={{ color: 'darkgrey', fontSize: 16, margin: 'auto' }}>
                Follow Us
          </Typography>
            </Hidden>
            <a href='https://www.facebook.com/fizio-2274062076185134/' style={{ color: 'black' }}><IconButton><FacebookIcon style={{ color: '#FF4500' }} /></IconButton></a>
            <a href='http://www.instagram.com/wearefizio' style={{ color: 'black' }}><IconButton><InstagramIcon style={{ color: '#FF4500' }} /></IconButton></a>
            <a href='http://www.twitter.com/wearefizio' style={{ color: 'black' }}><IconButton><TwitterIcon style={{ color: '#FF4500' }} /></IconButton></a>
          </div>
          {/* <div className={clsx(classes.menuButtonRight)}>
            <div style={{ display: 'flex' }}>
              <Hidden smDown>
                <Button component={Link} to={'/subscription'} style={{ color: '#FF4500' }}><span style={{ fontSize: 14 }}>Join Fizio Plus</span></Button>
              </Hidden>
              <Button onClick={() => setChecked(prev => !prev)}><TuneOutlinedIcon /></Button>
              {links}
            </div>
          </div> */}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton color="default" onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        {auth.uid && auth.uid ?
          <div className="">
            <Divider />
            <List>
              <ListItem button key='discover' component={Link} to='/'>
                <ListItemIcon><Discover /></ListItemIcon>
                <ListItemText primary={'Discover'} />
              </ListItem>
              <ListItem button key='feed' component={Link} to='/feed' data-tut=".fourth-step">
                <ListItemIcon><Following /></ListItemIcon>
                <ListItemText primary={'Following'} />
              </ListItem>
              <ListItem button key='bookmarks' component={Link} to='/bookmarks' data-tut=".fifth-step">
                <ListItemIcon><Bookmark /></ListItemIcon>
                <ListItemText primary={'Bookmarked'} />
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem key='collections' data-tut=".sixth-step">
                <ListItemIcon><Collection /></ListItemIcon>
                <ListItemText primary={'Collections'} />
              </ListItem>
              {collections && collections.map((collection, index) => {
                return (
                  <ListItem button key={index} onClick={() => history.push({
                    pathname: '/collection/query?collectionid=' + collection.id,
                    state: collection
                  })}>
                    <ListItemText primary={collection.name} />
                  </ListItem>
                )
              })}
            </List>
            <CreateCollection data-tut=".seventh-step" />
            <ListItem>
              <ListItemIcon><Collection /></ListItemIcon>
              <ListItemText primary={'Groups'} />
            </ListItem>
            {groups && groups.map((group, index) => {
              return (
                <ListItem button key={index} onClick={() => history.push({
                  pathname: '/groups/query?groupid=' + group.id,
                  state: group
                })}>
                  <ListItemText primary={group.name} />
                </ListItem>
              )
            })}
            <CreateGroup />
          </div>
          :
          <div className="">
            <Divider />
            <p className="" style={{ marginLeft: 20 }}>Sign Up or Login to use Fizio Features.</p>
          </div>
        }
      </Drawer>
      <main
        style={{ background: 'white' }}
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader}></div>
        {/* {renderTour()} */}
        <Collapse in={checked}>
          <Container maxWidth='md' style={{ minHeight: '20vh', marginTop: 40 }}>
            {/* <SearchBar /> */}
            <div></div>
          </Container>
        </Collapse>
        <Router />
      </main>
    </div>
  )
})

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.firebase.auth,
    collections: state.firestore.ordered.collections,
    groups: state.firestore.ordered.groups
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    { collection: 'collections', where: [['authorId', '==', (props.auth.uid ? props.auth.uid : '')]] },
    { collection: 'groups', where: [['authorId', '==', (props.auth.uid ? props.auth.uid : '')]] }
  ]))(Main)