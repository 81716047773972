import React from 'react'
import Container from '@material-ui/core/Container'

const Privacy = () => {

  React.useEffect(() => {
    window.scroll(0, 0)
  }, [true])

  return (
    <Container maxWidth='md'>
      <h2>Privacy Policy</h2>
      <p>This Privacy Policy was last updated on July 1, 2019.
</p>
      <h3>Our Policy</h3>
      <p>
        Protecting your private information is our priority. This Statement of Privacy
        applies to Fizio, Inc. (“Fizio”) and governs data collection and usage. For the
        purposes of this Privacy Policy, unless otherwise noted, all references to Fizio
        include (a) Fizio, Inc. and (b) our services, including websites, applications,
        API, and services.
</p>
      <h3>Our Services</h3>
      <p>
        The Websites, Applications (Apps), API, and all services provided by Fizio via
        each of the foregoing, now and as they become available in the future are
        referred to collectively, the “Services”. By using Fizio Services, you consent
        to the data practices described in this statement. As part of the Services,
        Fizio organizes and provides efficient means to access and receive updates
        regarding your contacts that may have been previously dispersed over many
        different Third Party Platforms. “Third Party Platforms” are services such as
        Gmail and Google Calendar and other similar platforms, from which you authorize
        us to import your contacts, email, and any additional information. Fizio’s
        Services enable you to access, organize and receive updates about those with
        whom you have personal relationships, including publicly available information
        about your professional contacts.
</p>
      <h3>Information We Collect</h3>
      <p>
        In order to better provide you with products and services offered on our
        Services, Fizio may collect personally identifiable information, such as your:
</p>
      <ul>
        <li>First and Last Name</li>
        <li>Mailing Address</li>
        <li>E-mail Address</li>
        <li>Phone Number</li>
        <li>Contact Information</li>
        <li>Log-in Credentials</li>
      </ul>
      <p>
        If you purchase Fizio's products and services, we collect billing and credit
        card information. This information is used to complete your purchase
        transactions.
</p>
      <p>
        We collect your name and contact information as well as your log-in credentials
        for each Third Party Platform (the “Third Party Login Credentials”) that you
        provide us.
</p>
      <p>
        Fizio may also collect anonymous demographic information, which is not unique to
        you, such as your:
</p>
      <ul>
        <li>Age</li>
        <li>Gender</li>
        <li>Height</li>
        <li>Weight</li>
        <li>Birthdate</li>
        <li>Country of Residence</li>
      </ul>
      <p>
        Please keep in mind that if you directly disclose personally identifiable
        information or personally sensitive data through Fizio's public message boards,
        this information may be collected and used by others.
</p>
      <p>
        We do not collect any personal information about you unless you voluntarily
        provide it to us. However, you may be required to provide certain personal
        information to us when you elect to use certain Services. These may Include: (a)
        registering for an account on our Services; (b) entering a sweepstakes or
        contest sponsored by us or one of our partners; (c) signing up for special
        offers from selected third parties; (d) sending us an email message; (e)
        submitting your credit card or other payment information when ordering and
        purchasing products and services. We will use your information for, but not
        limited to, communicating with you in relation to Services and/or products you
        have requested from us. We also may gather additional personal or non-personal
        information in the future.
</p>
      <p>
        By voluntarily providing us with Personal Data, you are consenting to our use of
        it in accordance with this Privacy Policy. If you provide Personal Data to us
        via our Services, you acknowledge and agree that such Personal Data may be
        transferred from your current location to the offices and servers of Fizio and
        the authorized third party service providers referred to herein located in the
        United States and abroad.
</p>
      <h3>Use of your Personal Information</h3>
      <p>
        Fizio collects and uses your personal information (“Personal Data”) to operate
        its Services and deliver the Services you have requested. Fizio may also use
        your personally identifiable information to inform you of other products or
        services available from Fizio and its affiliates.
</p>
      <p>
        If at any time you wish not to receive any future communications or you wish to
        have your name deleted from our mailing lists, please contact us as indicated
        below.
</p>
      <h3>Sharing Information with Third Parties</h3>
      <p>
        Fizio does not sell, rent or lease its customer lists to third parties.
</p>
      <p>
        Fizio may share data with trusted partners to help perform statistical analysis,
        send you email or postal mail, provide customer support, or arrange for
        deliveries. All such third parties are prohibited from using your personal
        information except to provide these services to Fizio, and they are required to
        maintain the confidentiality of your information.
</p>
      <p>
        Fizio sometimes engages other companies to perform certain business-related
        functions. Examples of such functions include mailing information, maintaining
        databases and processing payments. When we engage another company to perform a
        function of this nature, we only provide them with the information that they
        need to perform their specific function.
</p>
      <p>
        As we develop our business, we might sell or buy businesses or assets. In the
        event of a corporate sale, merger, reorganization, dissolution or similar event,
        Personal Data may be part of the transferred assets.
</p>
      <p>
        We may also share your Personal Data with the Fizio Related Companies for
        purposes consistent with this Privacy Policy.
</p>
      <p>
        Fizio may disclose your personal information, without notice, if required to do
        so by law or in the good faith belief that such action is necessary to: (a)
        conform to the edicts of the law or comply with legal process served on Fizio or
        the Services; (b) protect and defend the rights or property of Fizio; and/or (c)
        act under exigent circumstances to protect the personal safety of users of
        Fizio, or the public.
</p>
      <h3>Tracking User Behavior</h3>
      <p>
        Fizio may keep track of the websites, pages, and apps our users visit within the
        Services, in order to determine what Fizio services are the most popular. This
        data is used to deliver customized content and advertising within Fizio to
        customers whose behavior indicates that they are interested in a particular
        subject area.
</p>
      <h3>Automatically Collected Information</h3>
      <p>
        Information about your computer hardware and software may be automatically
        collected by Fizio. This information can include your:
</p>
      <ul>
        <li>IP Address</li>
        <li>Browser Type</li>
        <li>Domain Names</li>
        <li>Access Times</li>
        <li>Referring Website Addresses</li>
      </ul>
      <p>
        This information is used for the operation of the service, to maintain quality
        of the service, and to provide general statistics regarding use of the Fizio
        website.
</p>
      <p>
        Our Services may use third party services, including, without limitation, Google
        Analytics, a web analytics service provided by Google, Inc. (“Google”) that uses
        cookies to help us analyze how users use our Services. The information generated
        by the cookie about your use of our Services (including your IP address) will be
        transmitted to and stored by Google on our other third party service providers.
        Google and our other third party service providers use this information for the
        purpose of evaluating your use of the Services, compiling reports on website
        activity for website operators and providing other services relating to website
        activity and internet usage.
</p>
      <h3>Aggregated Personal Data</h3>
      <p>
        To better understand and serve the users of our Services, we sometimes conduct
        research on our customer demographics, interests and behavior based on the
        Personal Data and other information provided to us. This research may be
        compiled and analyzed on an aggregate basis, and we may share this aggregate
        data with our affiliates, agents and business partners. This aggregate
        information does not identify you personally.
</p>
      <p>
        We may also disclose aggregated user statistics in order to describe our
        Services to current and prospective business partners, and to other third
        parties for other lawful purposes.
</p>
      <h3>Use of Cookies</h3>
      <p>
        Fizio Services may use "cookies" to help you personalize your online experience.
        A cookie is a text file that is placed on your hard disk by a web page server.
        Cookies cannot be used to run programs or deliver viruses to your computer.
        Cookies are uniquely assigned to you, and can only be read by a web server in
        the domain that issued the cookie to you.
</p>
      <p>
        One of the primary purposes of cookies is to provide a convenience feature to
        save you time. The purpose of a cookie is to tell the web server that you have
        returned to a specific page. For example, if you personalize Fizio pages, or
        register with Fizio Services or services, a cookie helps Fizio to recall your
        specific information on subsequent visits. This simplifies the process of
        recording your personal information, such as billing addresses, shipping
        addresses, and so on. When you return to the same Fizio website, the information
        you previously provided can be retrieved, so you can easily use the Fizio
        features that you customized.
</p>
      <p>
        You have the ability to accept or decline cookies. Most web browsers
        automatically accept cookies, but you can usually modify your browser setting to
        decline cookies if you prefer. If you choose to decline cookies, you may not be
        able to fully experience the interactive features of the Fizio Services.
</p>
      <h3>Your Choices</h3>
      <p>
        You have the choice to determine which, if any, Third Party Login Credentials
        you elect to supply to us. You also have the choice to opt-out of receiving
        email marketing communications from us.
</p>
      <h3>Social Media</h3>
      <p>
        Through our Services, you can choose to access certain social media websites and
        services that are owned and/or controlled by third parties (such websites and
        services, collectively, the “Social Media Services”). When you elect to access
        and use the Social Media Services, you will be sharing your information (which
        will include personal information if you elect to share such information) with
        those Social Media Services.
</p>
      <p>
        As with other Third Party Sites, the information that you share with the Social
        Media Services will be governed by the privacy policies and terms of service of
        the providers of such Social Media Services and not by the policies and
        procedures we describe here. You may also be able to modify your privacy
        settings with these Social Media Services to, for example, control what
        information the Social Media Services disclose to other entities, including
        Company.
</p>
      <p>
        When you add Social Media Service accounts to the Company Services or log into
        our Services using your Social Media Services account, we will collect relevant
        information necessary to enable our Services to access that Social Media
        Service, however you will provide your login information, like your password,
        directly to such Social Media Service (and not to Company).
</p>
      <p>
        As part of such integration, the Social Media Service will provide Company with
        access to certain information that you have provided to such Social Media
        Service, and we will use, store and disclose such information in accordance with
        this Privacy Policy and, if and to the extent applicable, the policies of such
        Social Media Services. However, please remember that the manner in which Social
        Media Services use, store and disclose your information is governed by the
        policies of the applicable Social Media Services provider, and, as a result,
        Company shall not have any liability or responsibility for the privacy practices
        or other actions of Social Media Services that may be enabled within and/or
        otherwise accessible through our Services.
</p>
      <h3>Security of your Personal Information</h3>
      <p>
        Fizio secures your personal information from unauthorized access, use, or
        disclosure.
</p>
      <p>
        When personal information (such as a credit card number) is transmitted to other
        websites, it is protected through the use of encryption, such as the Secure
        Sockets Layer (SSL) protocol.
</p>
      <p>
        We strive to take appropriate security measures to protect against unauthorized
        access to or alteration of your personal information. Unfortunately, no data
        transmission over the Internet or any wireless network can be guaranteed to be
        100% secure. As a result, while we strive to protect your personal information,
        you acknowledge that: (a) there are security and privacy limitations inherent to
        the Internet which are beyond our control; and (b) security, integrity, and
        privacy of any and all information and data exchanged between you and us through
        this Services cannot be guaranteed.
</p>
      <h3>Children Under Thirteen</h3>
      <p>
        The Services are not designed for or intended for use by children and Fizio does
        not knowingly collect personally identifiable information from children under
        the age of thirteen. If you are under the age of 13, please do not submit any
        Personal Data through our Services. If you have reason to believe that a child
        under the age of 13 has provided Personal Data to Fizio through this Services,
        please contact us, and we will endeavor to delete that information from our
        databases.
</p>
      <h3>E-mail Communications</h3>
      <p>
        From time to time, Fizio may contact you via email for the purpose of providing
        announcements, promotional offers, alerts, confirmations, surveys, and/or other
        general communication. In order to improve our Services, we may receive a
        notification when you open an email from Fizio or click on a link therein.
</p>
      <p>
        If you would like to stop receiving marketing or promotional communications via
        email from Fizio, you may opt out of such communications by clicking the
        unsubscribe button within the body of an email from Fizio, Inc. or by contacting
        us as indicated below.
</p>
      <h3>External Data Storage Sites</h3>
      <p>
        We may store your data on servers provided by third party hosting vendors with
        whom we have contracted.
</p>
      <h3>Links to Other Web Sites</h3>
      <p>
        This Privacy Policy applies only to our Services. Our Services may contain links
        to other web sites not operated or controlled by us (the “Third Party Sites”).
        The policies and procedures we described here do not apply to the Third Party
        Sites. The links from our Services do not imply that we endorse or have reviewed
        the Third Party Sites. We suggest contacting those sites directly for
        information on their privacy policies.
</p>
      <h3>Changes to this Statement</h3>
      <p>
        Fizio reserves the right to change this Privacy Policy from time to time. We
        will notify you about significant changes in the way we treat personal
        information by sending a notice to the
</p>
      <p>
        primary email address specified in your account, by placing a prominent notice
        on our site, and/or by updating any privacy information on this page. Your
        continued use of the Services and/or Services available through this Services
        after such modifications will constitute your: (a) acknowledgment of the
        modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
</p>
      <h3>Other Terms and Conditions</h3>
      <p>
        Your access to and use of our Services is subject to the Terms of Use.
</p>
      <h3>Contact Information</h3>
      <p>
        Fizio welcomes your questions or comments regarding this Statement of Privacy.
        If you believe that Fizio has not adhered to this Statement, please contact
        Fizio as follows: info@fizio.co
</p>
    </Container>
  )
}

export default Privacy
