import authReducer from './authReducer'
// import videoReducer from './videoReducer'
import contentReducer from './contentReducer'
import collectionReducer from './collectionReducer'
import groupReducer from './groupReducer'
import followReducer from './followReducer'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
  auth: authReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  collections: collectionReducer,
  following: followReducer,
  bookmarks: contentReducer,
  groups: groupReducer
});
export default rootReducer