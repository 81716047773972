import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'

import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Fab from "@material-ui/core/Fab"
import AddSource from './AddSource'

import IconButton from '@material-ui/core/IconButton'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ScaleIn from 'material-ui/internal/ScaleIn';

const useStyles = makeStyles(theme => ({
  fab: {
    margin: 0 // theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));


const SignedInLinks = (props) => {
  const classes = useStyles();
  const { profile } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  }
  const handleClose = (e) => {
    setAnchorEl(null);
  }

  return (
    <div style={{ display: 'flex' }}>
      {/* <AddSource /> */}
      {/* <Fab onClick={handleClick} size="small" className={classes.fab} style={{ overflow: 'hidden' }}>
        <img className="" alt='' width='100%' height='100%' src={profile.photoUrl} mode='cover' />
  </Fab> */}
      <IconButton onClick={handleClick} style={{ fontSize: 'large' }}>
        <AccountCircleIcon style={{ transform: 'scale(1.4)' }} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem component={Link} to={'/profile/' + profile.displayName} onClick={handleClose}>Profile</MenuItem> */}
        {/* <MenuItem component={Link} to={'/account/' + profile.displayName} onClick={handleClose}>My Account</MenuItem> */}
        <MenuItem onClick={props.signOut}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SignedInLinks)