import React from 'react';
const PreviewProfilePicture = (props) => {
  const { pictureUrl, placeholder } = props;
  if (!pictureUrl) {
    return (
      <div style={{
        height: '120px',
        width: '120px',
        borderWidth: '.1rem',
        borderStyle: 'solid', borderColor: 'lightgrey',
        borderRadius: '60px'
      }} className="text-center  cropped-upper">
        <img className="img-fluid mb-2 mt-2 responsive cropped-imgg" alt='' width='100%' height='100%' src={placeholder} mode='cover' />
      </div>
    );
  }
  else {
    return (
      <div style={{
        height: '120px',
        width: '120px',
        borderWidth: '.1rem',
        borderStyle: 'solid', borderColor: 'lightgrey',
        borderRadius: '60px',
        overflow: 'hidden'
      }} className="text-center  cropped-upper">
        <img className="img-fluid mb-2 mt-2 responsive cropped-imgg" alt='' width='100%' height='100%' src={pictureUrl} mode='cover' />
      </div>
    );
  }
};
export default PreviewProfilePicture;