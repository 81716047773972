import React, { Component } from 'react';

import SourceList from './SourceList';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Pagination from 'react-js-pagination';
import './Pagination.css';
import firebase from '../../config/fbConfig';
import CircularProgress from '@material-ui/core/CircularProgress';

import Container from '@material-ui/core/Container'

class Sources extends Component {
  state = {
    sources: [],
    filtered: [],
    activePage: 1,
    typeSelect: 'Any',
    modalitySelect: 'Any',
    modalities: [],
    filterKeys: { type: 'Any', modality: 'Any' }
  };

  refreshData = () => {
    console.log('refresh data called')
    this.setState(
      {
        sources: [],
        filtered: []
      },
      () => {
        let db = firebase.firestore();
        db.collection('sources')
          .where(
            'searchterms',
            'array-contains',
            this.props.match.params.term
          )
          .get()
          .then(snap => {
            if (!snap.empty) {
              var data = snap.docs.map(doc => {
                return doc.data();
              });
              let modArr = this.setModalities(data)
              this.setState(
                { sources: data, typeFilter: 'Any', modalityFilter: 'Any', filtered: [], modalities: modArr, isResultEmpty: false }
              );
            } else {
              this.setState(
                { sources: [], typeFilter: 'Any', modalityFilter: 'Any', filtered: [], isResultEmpty: true, modalities: this.setModalities() },
                () => console.log(this.state));
            }
          })
          .catch(err => console.log(err));
      }
    );
  };

  componentDidMount() {
    this.refreshData()
  }

  static getDerivedStateFromProps(props, state) {
    // console.log('derived', props, state)
    return {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.refreshData()
      return false
    }
    return true
  }

  setModalities = (data) => {
    let modalitiesArr = []
    data && data.forEach(element => {
      let mods = element.modality.split(',')
      mods.forEach(item => { modalitiesArr.push(item) })
    });
    let dedup = modalitiesArr.reduce((unique, item) => {
      return unique.includes(item) ? unique : [...unique, item]
    }, []);
    return dedup
  }

  onSelectModality = (e, value) => {
    this.setState({
      modalitySelect: e.target.value,
      filterKeys: {
        type: this.state.filterKeys.type,
        modality: value.props.id
      }
    }, () => this.filterCollected())
  }

  onSelect = (e, value) => {
    this.setState({
      typeSelect: e.target.value,
      filterKeys: {
        type: value.props.id,
        modality: this.state.filterKeys.modality,
      }
    }, () => this.filterCollected())

  };

  filterCollected = () => {

    let firstFilter = this.state.sources.filter(source => {
      if (this.state.filterKeys.type !== 'Any') {
        return source.type === this.state.filterKeys.type
      } else {
        return source
      }
    });

    this.setState({
      modalities: this.setModalities(firstFilter),
      filtered: firstFilter.filter(source => {
        if (this.state.filterKeys.modality !== 'Any') {
          return source.modality.split(',').includes(this.state.filterKeys.modality)
        } else {
          return firstFilter
        }
      })
    })
  }

  paginatedArray = (array, pageSize, activePage) => {
    return array.slice(pageSize * (activePage - 1), pageSize * activePage);
  };

  handlePageChange = pageNumber => {
    this.setState(
      {
        activePage: pageNumber
      },
      () => window.scrollTo(0, 0)
    );
  };

  render() {
    const { sources, typeSelect, modalitySelect, activePage, filtered, isResultEmpty } = this.state;

    console.log(this.state)

    if (sources.length === 0 && typeof isResultEmpty === 'undefined') {
      return (
        <div className='' style={{ margin: 'auto', textAlign: 'center' }}>
          <CircularProgress style={{ color: '#FF4500' }} />
        </div>
      )
    } else if (isResultEmpty === true) {
      return (
        <div className="" style={{ textAlign: 'center' }}>
          Your search return 0 results. Try again.
        </div>
      )

    } else
      return (
        <div>
          <Container maxWidth="md">
            <div className='' style={{ display: 'flex' }}>
              <div className='' style={{ width: '100%', margin: 'auto' }}>
                <div
                  className=''
                  style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h6>
                    {filtered && filtered.length
                      ? filtered.length
                      : sources.length}{' '}
                    RESULTS FOR : '{this.props.match.params.term.toUpperCase()}'
                  </h6>
                  <div
                    className=''
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <h6>FILTER BY</h6>
                    <FormControl
                      className=''
                      style={{ padding: '20px 0px 20px 20px', width: 180 }}>
                      <Select
                        value={typeSelect}
                        onChange={this.onSelect}
                        name='source'
                        displayEmpty={false}
                        inputProps={{ 'aria-label': 'Any' }}
                      >
                        <MenuItem value='Any' id='Any'>
                          <em>Any</em>
                        </MenuItem>
                        <MenuItem value='Blogs' id='blog'>
                          Blogs
                        </MenuItem>
                        <MenuItem value='Audio' id='podcast'>
                          Audio
                        </MenuItem>
                        <MenuItem value='Video' id='youtube'>
                          Video
                        </MenuItem>
                        <MenuItem value='People' disabled>
                          People
                        </MenuItem>
                        <MenuItem value='Places' disabled>
                          Places
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <h6>MODALITY</h6>
                    <FormControl className="" style={{ padding: '20px', width: 180 }}>
                      <Select
                        value={modalitySelect}
                        onChange={this.onSelectModality}
                        name="modality"
                        displayEmpty={false}
                        inputProps={{ 'aria-label': 'Any' }}
                      >
                        <MenuItem value='Any' id='Any'>
                          <em>Any</em>
                        </MenuItem>
                        {this.state.modalities && this.state.modalities.map((item, index) => (
                          <MenuItem className="" value={item} key={index} id={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <SourceList
                  sources={
                    filtered && filtered.length
                      ? this.paginatedArray(
                        filtered,
                        12,
                        activePage && activePage ? activePage : 1
                      )
                      : this.paginatedArray(
                        sources,
                        12,
                        activePage && activePage ? activePage : 1
                      )
                  }
                />
                <div
                  className=''
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    display: 'flex'
                  }}>
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={12}
                    totalItemsCount={
                      filtered && filtered.length
                        ? filtered.length
                        : sources.length
                    }
                    pageRangeDisplayed={12}
                    onChange={this.handlePageChange}
                    itemClass='item'
                    linkClass='linkClass'
                    activeLinkClass='activeLinkClass'
                    className='ul.pagination'
                  />
                </div>
              </div>
            </div>
          </Container >
        </div>
      );
  }
}

export default Sources;
