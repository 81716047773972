const initState = {
  following: {

  }
}

const followReducer = (state = initState, action) => {
  switch (action.type) {
    case 'FOLLOW':
      console.log('follow success')
      return state;
    case 'FOLLOW_ERROR':
      console.log('follow error', action.err)
      return state;
    case 'UNFOLLOW':
      console.log('unfollow success')
      return state;
    case 'UNFOLLOW_ERROR':
      console.log('unfollow error', action.err)
      return state;
    default:
      return state
  }
}

export default followReducer