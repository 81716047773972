var defaultProfileUrl = 'https://firebasestorage.googleapis.com/v0/b/fizio-staging.appspot.com/o/images%2Fplaceholder.png?alt=media&token=09a76449-0698-45cc-9931-bb193dd75874'

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.auth().signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    ).then(() => {
      dispatch({ type: 'LOGIN_SUCCESS' })
    }).catch((err) => {
      dispatch({ type: 'LOGIN_ERROR', err })
    })
  }
}

export const signUpGoogle = (credentials) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    var googleProvider = new firebase.auth.GoogleAuthProvider()

    console.log(googleProvider)

    firebase.auth().signInWithPopup(googleProvider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        console.log(token, user)
        firebase.user(user.uid)
          .set({
            displayName: user.displayName,
            email: user.email,
            roles: {}
          })
          .then(() => {
            firestore.collection('users').doc(user.uid).set({
              email: user.email,
              displayName: user.displayName,
              photoUrl: defaultProfileUrl
            })
              .then(() => {
                dispatch({ type: 'SIGNUP_GOOGLE_SUCCESS' })
              })
          })
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        dispatch({ type: 'SIGNUP_GOOGLE_ERROR' })
      });
  }
}

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.auth().signOut().then(() => {
      localStorage.clear();
      dispatch({ type: 'SIGNOUT_SUCCESS' })
    });
  }
}

export const forgotPassword = (email) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase()
    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        dispatch({ type: 'EMAIL_RESET_SUCCESS' })
      }).catch(() => {
        dispatch({ type: 'EMAIL_RESET_ERROR' })
      })
  }
}

export const signUp = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase.auth()
      .createUserWithEmailAndPassword(
        newUser.email,
        newUser.password
      ).then(authUser => {

        dispatch({ type: 'SIGNUP_SUCCESS' })

        firestore.collection('users').doc(authUser.user.uid).set({
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          email: newUser.email,
          displayName: newUser.userName,
          photoUrl: defaultProfileUrl
        })

        if (newUser.profile !== '') {
          var file = newUser.picture

          var metadata = {
            contentType: newUser.picture.type
          }
          var uploadImageTask = firebase.storage().ref()
            .child(`images/${new Date().getTime()}` + file.name).put(file, metadata)

          uploadImageTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(progress)
            },
            (error) => {
              console.log(error.code)
            },
            () => {
              console.log('complete')
              uploadImageTask.snapshot.ref.getDownloadURL().then(url => {
                firestore.collection('users').doc(authUser.user.uid).update({
                  photoUrl: url
                })
              })
            })
        }
      }).catch(err => {
        dispatch({ type: 'SIGNUP_ERROR', err })
      })
  }
}

export const updateProfile = (userInfo) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const uid = getState().firebase.auth.uid;


    firestore.collection('users').doc(uid).update({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      displayName: userInfo.displayName,
      photoUrl: userInfo.photoUrl ? defaultProfileUrl : userInfo.photoUrl
    })

    if (userInfo.picture && userInfo.picture !== '') {
      var file = userInfo.picture

      var metadata = {
        contentType: userInfo.picture.type
      }
      var uploadImageTask = firebase.storage().ref()
        .child(`images/${new Date().getTime()}` + file.name).put(file, metadata)

      uploadImageTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(progress)
        },
        (error) => {
          console.log(error.code)
        },
        () => {
          console.log('complete')
          uploadImageTask.snapshot.ref.getDownloadURL().then(url => {
            firestore.collection('users').doc(uid).update({
              photoUrl: url
            })
          })
        })
    }
  }

}