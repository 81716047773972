import React from 'react'
import ContentCard from './ContentCard'

const ContentList = (props) => {
  console.log(props)
  const {content} = props;
  return (
    <div className="">
      <div className="row">
        {content.items && content.items.map((item, index) => {
          return (
            <ContentCard content={item} parentSource={props.content.parentSource} key={index}/>
          )
        })}
      </div>
    </div>
  )
}

export default ContentList
