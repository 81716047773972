import React, { useState, useEffect } from 'react'
import firebase from '../../config/fbConfig'

import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container'
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Bookmark from '@material-ui/icons/Bookmark';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';


import PickList from '../main/PickList'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Favorite from '@material-ui/icons/Favorite'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import ReactHtmlParser from 'react-html-parser';
import Fab from '@material-ui/core/Fab';
import { checkPropTypes } from 'prop-types'


var moment = require('moment')

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
}));

const Discover = (props) => {
  const classes = useStyles();
  const [sources, setSources] = useState('')

  useEffect(() => {
    // console.log(sources)
  }, [sources])

  const getMainImage = (item) => {
    const src = '';
    const type = item.type;
    switch (type) {
      case 'youtube':
        const thumb = item.link.split('=').pop()
        return 'http://img.youtube.com/vi/' + thumb + '/0.jpg'
      case 'instagram':
        return item.enclosure.url
      case 'blog':
        return item.imageURL
      case 'podcast':
        return item.imageURL
      default:
        break;
    }

  }

  const getTodayString = () => {
    var now = new Date()
    var mom = moment(now).format('MMMM Do')
    return mom
  }

  const { collections, content } = props
  var item1;
  var item2;
  if (collections && collections.length > 0) {
    item1 = collections[Math.floor(Math.random() * collections.length)];
    item2 = collections[Math.floor(Math.random() * collections.length)];
  }

  return (
    <div>
      <div style={{ textAlign: 'center', paddingTop: 40 }}>
        <h1 style={{ color: 'black', fontSize: 45, fontFamily: 'Proxima Nova', width: 300, margin: 'auto', lineHeight: 'normal' }}>Discover content that inspires you to move.</h1>
        <p style={{ fontFamily: 'Proxima Nova', width: 300, margin: 'auto', marginTop: 20, fontWeight: 'bold' }}>Fizio shows you hand selected training and exercise content from leading minds and rising stars all in one place.</p>
        <div style={{ justifyContent: 'space-evenly', width: 300, margin: 'auto', display: 'flex', paddingTop: 30 }}>
          <Fab variant="extended" component={Link} to={'/subscription'} className={classes.fab} style={{ background: '#FF4500', color: 'white', textTransform: 'none' }}>
            Join Fizio
          </Fab>
          <Fab variant="extended" component={Link} to={'/signin'} className={classes.fab} style={{ background: 'white', color: '#FF4500', textTransform: 'none' }}>
            Sign In
          </Fab>
        </div>
        {collections && collections.length > 0 ? <div>
          <div style={{ width: 400, margin: 'auto', paddingTop: 40 }}>
            <p style={{ fontFamily: 'Proxima Nova', width: 300, margin: 'auto', marginBottom: 20, fontWeight: 'bold' }}>Get hand-picked fitness and wellness content every day.</p>
            <Card>
              <CardContent style={{ padding: 0 }}>
                <img src={item1.photoUrl} style={{ width: '100%' }} alt="" />
              </CardContent>
              <CardContent>
                <Avatar src={item1.authorProfileUrl} style={{ width: 55, height: 55, boxShadow: '3px 3px #F8F8F8', margin: '10px auto 10px auto' }} />
                <h6 style={{ color: '#FF4500', fontFamily: 'Proxima Nova', fontWeight: 'bold', margin: 0 }}>COLLECTION</h6>
                <h1 style={{ fontFamily: 'Proxima Nova' }}>{item1.name}</h1>
                <p style={{ fontFamily: 'Proxima Nova' }}>{item1.description}</p>
              </CardContent>
            </Card>
          </div>
          <div style={{ width: 400, margin: 'auto', paddingTop: 40 }}>
            <p style={{ fontFamily: 'Proxima Nova', width: 300, margin: 'auto', marginBottom: 20, fontWeight: 'bold' }}>Discover gems with our personalized content recommendation engine.</p>
            <Card>
              <CardContent style={{ padding: 0 }}>
                <img src={item2.photoUrl} style={{ width: '100%' }} alt="" />
              </CardContent>
              <CardContent>
                <Avatar src={item2.authorProfileUrl} style={{ width: 55, height: 55, boxShadow: '3px 3px #F8F8F8', margin: '10px auto 10px auto' }} />
                <h6 style={{ color: '#FF4500', fontFamily: 'Proxima Nova', fontWeight: 'bold', margin: 0 }}>COLLECTION</h6>
                <h1 style={{ fontFamily: 'Proxima Nova' }}>{item2.name}</h1>
                <p style={{ fontFamily: 'Proxima Nova' }}>{item2.description}</p>
              </CardContent>
            </Card>
          </div>
        </div>
          :
          <div></div>
        }
      </div>
      <div style={{ textAlign: 'center', paddingTop: 40 }}>
        <h1 style={{ color: 'black', fontSize: 25, fontFamily: 'Proxima Nova', width: 300, margin: 'auto', lineHeight: 'normal' }}>Explore Top Picks on Fizio</h1>
        <p style={{ fontFamily: 'Proxima Nova', width: 300, margin: '20px auto 20px auto', fontWeight: 'bold' }}>Today {getTodayString()}</p>
        <Container>
          <Grid container spacing={3}>
            {content && content.map((item, index) => {
              return (
                <Grid key={index} item xs={12} sm={6}>
                  <Card>
                    <CardActionArea style={{ padding: 0 }}>
                      <a href={item.link} target='_blank' rel='noopener noreferrer'><img src={getMainImage(item)} style={{ width: '100%' }} alt="" /></a>
                    </CardActionArea>
                    <CardActions style={{ justifyContent: 'space-between' }}>
                      <Avatar src={item.imageURL} style={{ width: 45, height: 45, boxShadow: '3px 3px #F8F8F8' }} />
                      <p style={{ fontFamily: 'Proxima Nova', fontSize: '1em', color: 'darkgrey' }}>{item.titleClean}</p>
                      <IconButton>
                        <MoreVertIcon />
                      </IconButton>
                    </CardActions>
                    <CardContent>
                      <h6 style={{ color: '#FF4500', fontFamily: 'Proxima Nova', fontWeight: 'bold', margin: 5 }}>{item.type.toUpperCase()}</h6>
                      <h6 style={{ color: 'darkgrey', fontFamily: 'Proxima Nova', fontWeight: 'bold', margin: 0 }}>{item.title}</h6>
                    </CardContent>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        </Container>
      </div>
    </div >
  )
};

const mapStateToProps = (state, ownprops) => {
  console.log(state, ownprops)
  return {
    auth: state.firebase.auth,
    content: state.firestore.ordered.content,
    sources: state.firestore.ordered.sources,
    videos: state.firestore.ordered.videos,
    collections: state.firestore.ordered.collections
  }
}
export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'content', orderBy: [['clout', 'desc']], limit: 12 },
    { collection: 'collections', where: [['featured', '==', true]] },
  ])
)(Discover)
