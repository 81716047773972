import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'
var config = {
  apiKey: "AIzaSyDjCUA_PIOtJK2jfCynU0GsDcQBowqLYMo",
  authDomain: "fizio-staging.firebaseapp.com",
  databaseURL: "https://fizio-staging.firebaseio.com",
  projectId: "fizio-staging",
  storageBucket: "fizio-staging.appspot.com",
  messagingSenderId: "542958971152"
};

firebase.initializeApp(config);
export default firebase;