import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container';

import qs from 'query-string'
import firebase from '../../config/fbConfig'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import SourceList from '../source/SourceList'


const podcast = require('../../res/icon-audio.svg');
const blog = require('../../res/icon-text.svg');
const youtube = require('../../res/icon-video.svg');



const GroupDetail = (props) => {
  console.log(props)
  const [group, setGroup] = useState({});
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
    if (Object.entries(group).length === 0) {
      if (!props.location.state) {
        let values = qs.parse(props.location.search)
        const db = firebase.firestore()
        db.collection('groups').doc(values.collectionid).get()
          .then(snap => {
            if (!snap.empty) {
              let result = snap.data()
              setGroup(result)
            } else {
              console.log('did not find')
            }
          }).catch(err => console.log(err))
      } else {
        setGroup(props.location.state)
      }
    }
  }, []);

  const onCopy = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  if (group) {
    return (
      <div>
        <div style={{ display: 'flex', background: '#f8f8f8' }}>
          <Container maxWidth='md' style={{ display: 'flex' }}>
            <div className="" style={{ marginTop: 20 }}>
              <img src={group.photoUrl} alt="" width={200} />
            </div>
            <div style={{ margin: 20 }}>
              <div style={{ display: 'flex' }}>
                <h1 className="" style={{ fontWeight: 'bold', margin: 0, fontSize: 34 }}>{group.name}</h1>
                <CopyToClipboard text={window.location.href}
                  onCopy={onCopy}>
                  <Button><SendIcon /></Button>
                </CopyToClipboard>
              </div>
              <h6 className="" style={{ margin: 0 }}>{group.description}</h6>
              <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: 12, color: 'black' }}>Curated by:</p>
              <div className="" style={{ display: 'flex' }}>
                <div className="" style={{ marginTop: 20 }}>
                  <img src={group.authorProfileUrl} width='60px' alt="" style={{ overflow: 'hidden', borderRadius: 15, background: 'white' }} />
                </div>
                <Link to={'/profile/' + group.authorDisplayName} style={{ margin: 0 }}>
                  <p className="" style={{ fontWeight: 'bold', fontSize: 18, color: 'black', marginLeft: 20 }}> {group.authorDisplayName}</p>
                </Link>
              </div>
            </div>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">Link Copied!</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          </Container>
        </div>
        <Container maxWidth='md' style={{ marginTop: 20 }}>
          {
            group.content && group.content.length > 0 ?
              <div className="">
                <SourceList sources={group.content} />
              </div>
              :
              <div></div>
          }
        </Container>
      </div>
    )

  } else {
    return (
      <div className='' style={{ margin: 'auto', textAlign: 'center' }}>
        <CircularProgress style={{ color: '#FF4500' }} />
      </div>
    )
  }
}

export default GroupDetail
