import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import firebase from '../../config/fbConfig'
import { followSource, unfollowSource } from '../../store/actions/followActions'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Button from '@material-ui/core/Button';
import ContentList from '../content/ContentList'
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container'
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import mail from '../../res/mail-line.svg'
import link from '../../res/link-line.svg'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';


let Parser = require('rss-parser');

const CORS_PROXY = "https://cors-anywhere.herokuapp.com/"


class SourceDetail extends Component {

  state = {
    parent: {},
    dataSource: [],
    feed: {},
    open: false
  }

  componentDidMount() {
    this.refreshData()
  }

  refreshData = () => {
    let db = firebase.firestore();
    db.collection('sources')
      .where(
        'titleClean',
        '==',
        this.props.match.params.title
      )
      .get()
      .then(snap => {
        if (!snap.empty) {
          var data = snap.docs.map(doc => {
            return doc.data();
          });
          this.setState({
            parent: data[0]
          }, () => {
            if (typeof data[0].rss === 'undefined') {
              this.getFeedData(data[0].rssURL)
            } else {
              this.setState({
                dataSource: data[0].rss
              })
            }
          })
        }
      })

  }

  getFeedData = (url) => {
    let parser = new Parser();
    parser.parseURL(CORS_PROXY + url, (err, feed) => {
      this.setState({ dataSource: feed })
    })
  }

  renderButtonValue = () => {
    if (this.props.following && this.props.following.length && this.state.parent) {
      let following = this.props.following.filter(item => {
        return parseInt(item.id) === parseInt(this.state.parent.id)
      })
      if (following.length) {
        return "FOLLOWING"
      } else {
        return "FOLLOW"
      }
    } else {
      return "FOLLOW"
    }
  }

  handleOpen = () => {
    this.setState({
      open: true
    })
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      open: false
    })
  };

  handleClickFollow = e => {
    if (e.target.textContent === 'FOLLOWING') {
      this.props.unfollowSource(this.state.parent)
    } else {
      this.props.followSource(this.state.parent)
    }
  };

  // renderDashboard = () => {
  //   const { parent, dataSource } = this.state
  //   return (
  //     <Grid container>
  //       <Grid item xs={6}>
  //         <Card style={{ margin: '5px 5px 5px 5px', textAlign: 'center' }}>
  //           <CardContent>
  //             <p>Fizio Score:</p>
  //             <h1 style={{ color: '#FF4500', fontWeight: 'bold' }}>{Math.floor(Math.random() * 13) + 78}%</h1>
  //           </CardContent>
  //         </Card>
  //       </Grid>
  //       <Grid item xs={6}>
  //         <Card style={{ margin: '5px 5px 5px 5px', textAlign: 'center' }}>
  //           <CardContent>
  //             <p>Relevance:</p>
  //             <h1 style={{ color: '#FF4500', fontWeight: 'bold' }}>{Math.floor(Math.random() * 22) + 65}%</h1>
  //           </CardContent>
  //         </Card>
  //       </Grid>
  //       <Grid item xs={6}>
  //         <Card style={{ margin: '5px 5px 5px 5px', textAlign: 'center' }}>
  //           <CardContent>
  //             <p>Ads:</p>
  //             <h1 style={{ color: '#FF4500', fontWeight: 'bold' }}>YES</h1>
  //           </CardContent>
  //         </Card>
  //       </Grid>
  //       <Grid item xs={6}>
  //         <Card style={{ margin: '5px 5px 5px 5px', textAlign: 'center' }}>
  //           <CardContent>
  //             <p>Clout:</p>
  //             <h1 style={{ color: '#FF4500', fontWeight: 'bold' }}>N/A</h1>
  //           </CardContent>
  //         </Card>
  //       </Grid>
  //     </Grid>
  //   )
  // }

  renderHeader = (parent) => {
    console.log(parent)
    return (
      <div>
        {parent && parent ?
          <div className="row" style={{ padding: 20 }}>
            <div className="" style={{ margin: 'auto', display: 'flex', justifyContent: 'space-between' }}>
              <h1 className="" style={{ fontWeight: 'bold' }}>{parent.titleClean}</h1>
              <div>
                {this.props.auth.uid ?
                  <Button value={this.renderButtonValue()} onClick={this.handleClickFollow} variant="outlined" style={{ width: 100, height: 40, border: '1px solid #FF4500', borderRadius: 4, color: '#FF4500', boxShadow: 'none', backgroundColor: 'white', fontSize: 12, margin: 20 }}>{this.renderButtonValue() ? this.renderButtonValue() : ''}</Button>
                  :
                  <div className=""></div>
                }
                <Button onClick={() => {
                  window.location.href = `mailto:?subject=${encodeURIComponent('Check out what I found on Fizio!')}&body=${document.URL} ${encodeURIComponent('You can find the link here: ')} ${window.location.href}`
                }}><img src={mail} alt="" /></Button>
                <CopyToClipboard text={window.location.href}
                  onCopy={() => this.handleOpen()}>
                  <Button><img src={link} alt="" /></Button>
                </CopyToClipboard>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  open={this.state.open}
                  autoHideDuration={6000}
                  onClose={this.handleClose}
                  ContentProps={{
                    'aria-describedby': 'message-id',
                  }}
                  message={<span id="message-id">{'Link Copied!'}</span>}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.handleClose}
                    >
                      <CloseIcon />
                    </IconButton>,
                  ]}
                />
              </div>
            </div>
            <div>
              <h4 style={{ color: '#FF4500' }}>{parent.type && parent.type.toUpperCase()}</h4>
            </div>
            <div className="">
              {parent.descriptionClean ?
                <h6 className="">{this.forceUnicodeEncoding(parent.descriptionClean)}</h6>
                :
                <div></div>
              }
            </div>
            {/* {this.renderDashboard()} */}
            <p><em style={{ color: 'grey' }}>Disclaimer: The content below may include ads, affiliate links, branded products, and sponsored content not affiliated with Fizio.</em></p>
            <Divider />
          </div>
          :
          <div></div>
        }
      </div>
    )
  }

  renderList = (dataSource) => {
    console.log(dataSource)
    return (
      <div>
        <Container maxWidth='md'>
          <p>Showing the latest {dataSource.items && dataSource.items.length} posts</p>
          <div className="" style={{ display: 'flex' }}>
            <div>
              {dataSource && dataSource.items ?
                <ContentList content={{ ...dataSource, 'parentSource': this.state.parent }} type={this.state.parent.type} />
                :
                <div className='' style={{ margin: 'auto', textAlign: 'center' }}>
                  <CircularProgress style={{ color: '#FF4500' }} />
                </div>
              }
            </div>
          </div>
        </Container>
      </div>
    )
  }

  forceUnicodeEncoding = (string) => {
    return decodeURIComponent(escape(string));

  }

  render() {
    const { dataSource, parent } = this.state;
    return (
      <Container className="" maxWidth='md'>
        {this.renderHeader(parent)}
        {this.renderList(dataSource)}
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    following: state.firestore.ordered.following
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    followSource: (source) => dispatch(followSource(source)),
    unfollowSource: (source) => dispatch(unfollowSource(source))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'following/' + props.auth.uid + '/userFollowing', storeAs: 'following' }
  ])
)(SourceDetail)

