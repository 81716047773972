import uuid from 'react-uuid'

export const addContentBookmark = (content) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const uid = getState().firebase.auth.uid;

    const uuidString = uuid()

    var ref = firestore.collection('bookmarks/' + uid + '/userBookmarks').doc(uuidString)
    ref.set({
      ...content,
      uuid: uuidString
    }).then(() => {
      dispatch({ type: 'BOOKMARK' })
    }).catch(err => {
      dispatch({ type: 'BOOKMARK_ERROR', err })
    })
  }
}

export const removeContentBookmark = (content) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore();
    const uid = getState().firebase.auth.uid;

    var ref = firestore.collection('bookmarks/' + uid + '/userBookmarks')
    ref.get().then((snapshot) => {
      if (!snapshot.empty) {
        snapshot.docs.forEach(doc => {
          if (doc.data().uuid === content.uuid) {
            ref.doc(doc.id).delete()
              .then(() => {
                console.log('delete')
                dispatch({ type: 'BOOKMARK_DELETE_SUCCESS' })
              })
              .catch((err) => {
                console.log(err)
                dispatch({ type: 'BOOKMARK_DELETE_ERROR' })
              })
          }
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }
}