import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signUp, signUpGoogle } from '../../store/actions/authActions';
import PreviewProfilePicture from './PreviewProfilePicture';
import NewFooter from '../footer/NewFooter';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const GoogleIcon = require('../../res/google.svg')
const GoogleButton = require('../../res/btngoogle.png')

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'lightgrey',
      },
    },
  },
})(TextField);

const solo = require('../../res/solo-run.svg');
const placeholder = require('../../res/placeholder-profile.svg');

export class SignUp extends Component {
  state = {
    email: '',
    password: '',
    userName: '',
    firstName: '',
    lastName: '',
    picture: ''
  };
  componentDidMount() {
    window.scroll(0, 0)
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.email !== '' && this.state.firstName !== '' && this.state.lastName !== '' && this.state.password !== '' && this.state.userName) {
      this.props.signUp(this.state);
    } else {
      // alert('Error: Please fill in all required fields.')
    }
  };
  handleFileSelection = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        picture: file,
        pictureUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  };
  render() {
    const { auth, authError } = this.props;

    if (auth.uid) return <Redirect to='/' />;

    return (
      <div className='container' style={{ width: '40%', margin: 'auto' }}>
        <div className='' style={{ textAlign: 'center', paddingTop: 40 }}>
          <img src={solo} alt='' />
        </div>
        <h4 style={{ fontWeight: 'bold' }}>Create your free account</h4>
        <form onSubmit={this.handleSubmit} autoComplete='none' noValidate>
          {/* <div className='s6 m12' style={{ display: 'flex' }}>
            <div className='' style={{ flex: 1 }}>
              <p>First Name (Required)</p>
              <CssTextField
                type='text'
                id='firstName'
                required
                style={styles.textFieldStyle}
                onChange={this.handleChange}
                variant='outlined'
                autoComplete="firstName"
              />
            </div>
            <div className='' style={{ width: 20 }}></div>
            <div className='input-field' style={{ flex: 1 }}>
              <p>Last Name (Required)</p>
              <CssTextField
                type='text'
                id='lastName'
                required
                style={styles.textFieldStyle}
                onChange={this.handleChange}
                variant='outlined'
                autoComplete="lastName"
              />
            </div>
          </div> */}
          <div className='input-field'>
            <p>Active Email Address (Required)</p>
            <CssTextField
              type='email'
              id='email'
              required
              style={styles.textFieldStyle}
              onChange={this.handleChange}
              variant='outlined'
              autoComplete="email"
            />
          </div>
          <div className='input-field'>
            <p>Username (Required)</p>
            <CssTextField
              type='text'
              id='userName'
              required
              style={styles.textFieldStyle}
              onChange={this.handleChange}
              variant='outlined'
              autoComplete="userName"
            />
          </div>
          <div className='input-field'>
            <p>Password (Required)</p>
            <CssTextField
              type='password'
              id='password'
              required
              style={styles.textFieldStyle}
              onChange={this.handleChange}
              variant='outlined'
              autoComplete="password"
            />
          </div>
          {/* <p>Profile Photo (Optional)</p>
          <div className='section' style={{ display: 'flex' }}>
            <div className=''>
              <PreviewProfilePicture
                picture={this.state.picture}
                pictureUrl={this.state.pictureUrl}
                placeholder={placeholder}
              />
            </div>

          </div> */}
          {/* <div className="">
            <Button className='' style={{ width: 200, height: 36, backgroundColor: '#FF4500', color: 'white' }}>
              <input
                accept="image/*"
                type='file'
                id='photoUrl'
                onChange={this.handleFileSelection}
                style={{
                  opacity: 0,
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer'
                }}
              />
              CHOOSE A FILE
              </Button>
          </div> */}
          <div
            className=''
            style={{
              height: 220,
              borderRadius: 8,
              paddingTop: 20,
              display: 'auto'
            }}>
            <div className=''>
              <div className='input-field'>
                <Button
                  onClick={this.handleSubmit}
                  style={{
                    width: 220,
                    height: 44,
                    borderRadius: 8,
                    background: '#FF4500',
                    boxShadow: 'none',
                    color: 'white'
                  }}>
                  Create Account
                </Button>
              </div>
              <div>
                <h4>- or -</h4>
                <Button onClick={() => this.props.signUpGoogle()}><img src={GoogleButton} alt='' style={{ maxHeight: 44 }} /></Button>
              </div>
              <div className="" style={{ marginTop: 20 }}>
                <span style={{ fontStyle: 'italic' }}>
                  By clicking 'Create Account', you agree to our{' '}
                  <Link to='/terms' style={{ color: '#FF4500' }}>
                    Terms of Service
                </Link>{' '}
                  and{' '}
                  <Link to='/privacy' style={{ color: '#FF4500' }}>
                    Privacy Policy
                </Link>
                </span>
              </div>
            </div>
          </div>
        </form>
        <div className=''>
          {authError ? <p>{authError}</p> : null}
        </div>
      </div>
    );
  }
}
const styles = {
  textFieldStyle: {
    borderRadius: '4px',
    width: '100%',
    height: '48px',
  }
};
const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};
const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => dispatch(signUp(newUser)),
    signUpGoogle: newUser => dispatch(signUpGoogle(newUser))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
