import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Divider from '@material-ui/core/Divider'
import ReactHtmlParser from 'react-html-parser';

const ContentCard = (props) => {
  const { type } = props.parentSource;

  const transform = (node, index) => {

    if (node.name === 'img') {
      return <Link
        key={index}
        to={{
          pathname: "/content/source?sourceid=" + props.parentSource.id + "&item=" + encodeURIComponent(props.content.title),
          state: props
        }}>
        <img src={node.attribs.src} style={{ maxWidth: '80vw' }} key={index} alt="" />
      </Link>
    }
  }

  const options = {
    decodeEntities: true,
    transform
  }

  if (type === 'podcast') {
    return (
      <div className="" style={{ minHeight: '100px', border: 'none', borderRadius: 4, margin: 5, padding: 20, display: 'flex' }}>
        <div className="" style={{ marginRight: 20 }}>
          <img src={props.content.itunes.image} width={220} alt="" className="" />
        </div>
        <div className="">
          <Link
            to={{
              pathname: "/content/source?sourceid=" + props.parentSource.id + "&item=" + encodeURIComponent(props.content.title),
              state: props
            }}><h2 className="" style={{ color: '#4C4C4C', fontWeight: 'bold' }}>{props.content.title}</h2></Link>
          <p style={{ fontSize: '12px' }}>Published: {moment(props.content.pubDate).format("MMMM Do YYYY")}</p>
        </div>
      </div>
    )

  } else if (type === 'blog') {
    return (
      <div className="" style={{ minHeight: '100px', border: 'none', borderRadius: 4, margin: 5, padding: 20, display: 'flex' }}>
        <div className="">
          <Divider />
          <Link
            to={{
              pathname: "/content/source?sourceid=" + props.parentSource.id + "&item=" + encodeURIComponent(props.content.title),
              state: props
            }}><h2 className="" style={{ color: '#4C4C4C', fontWeight: 'bold' }}>{props.content.title}</h2></Link>
          <p style={{ fontSize: '12px' }}>Published: {moment(props.content.pubDate).format("MMMM Do YYYY")}</p>
        </div>
      </div>
    )

  } else if (type === 'youtube') {

    const thumb = props.content.link.split('=').pop()

    return (
      <div className="" style={{ minHeight: '100px', border: 'none', borderRadius: 4, margin: 5, padding: 20, display: 'flex' }}>
        <div className="" style={{ marginRight: 20 }}>
          <Link
            to={{
              pathname: "/content/source?sourceid=" + props.parentSource.id + "&item=" + encodeURIComponent(props.content.title),
              state: props
            }}>
            <img src={'http://img.youtube.com/vi/' + thumb + '/0.jpg'} width={220} alt="" className="" /></Link>
        </div>
        <div className="">
          <Link
            to={{
              pathname: "/content/source?sourceid=" + props.parentSource.id + "&item=" + encodeURIComponent(props.content.title),
              state: props
            }}><h2 className="" style={{ color: '#4C4C4C', fontWeight: 'bold' }}>{props.content.title}</h2></Link>
          <p style={{ fontSize: '12px' }}>Published: {moment(props.content.pubDate).format("MMMM Do YYYY")}</p>
        </div>
      </div>
    )

  }
}

export default ContentCard
