import React from 'react'
import { Switch, Route } from 'react-router-dom'

import SignIn from '../components/auth/SignIn'
import SignUp from '../components/auth/SignUp'
import Forgot from '../components/auth/Forgot'
import Account from '../components/account/Account'
import ContactUs from '../components/footer/ContactUs'
// import About from '../components/footer/About'
// import Community from '../components/footer/Community'
import Terms from '../components/footer/Terms'
import Privacy from '../components/footer/Privacy'
// import ThankYou from '../components/videos/ThankYou'
import Discover from '../components/main/Discover'
import Sources from '../components/source/Sources'
import SourceDetail from '../components/source/SourceDetail'
import Feed from '../components/main/Feed'
import Bookmarks from '../components/main/Bookmarks'
import ContentDetail from '../components/content/ContentDetail'
import ProfileDetail from '../components/profile/ProfileDetail'
import CollectionDetail from '../components/collection/CollectionDetail'
import GroupDetail from '../components/groups/GroupDetail'
import Subscription from '../components/subscription/Subscription'
import FAQ from '../components/footer/FAQ'


const Router = (props) => {
  return (
    <div>
      <Switch>
        <Route exact path='/' component={Discover} />
        <Route path="/signin" component={SignIn} />
        <Route path="/signup" component={SignUp} />
        <Route path="/forgot" component={Forgot} />
        <Route path="/feed" component={Feed} />
        <Route path="/bookmarks" component={Bookmarks} />
        <Route path="/results/:term" component={Sources} />
        <Route path="/source/:title" component={SourceDetail} />
        <Route path="/content/:title" component={ContentDetail} />
        <Route path="/profile/:handle" component={ProfileDetail} />
        <Route path="/account/:slug" component={Account} />
        <Route path="/contactus" component={ContactUs} />
        <Route path="/collection/:name" component={CollectionDetail} />
        <Route path="/terms" component={Terms}/>
        <Route path="/privacy" component={Privacy}/>
        <Route path="/groups/:groupid" component={GroupDetail}/>
        <Route path="/subscription" component={Subscription}/>
        <Route path="/FAQ" component={FAQ}/>

      </Switch>
    </div>
  )
}

export default Router
