import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { addSourceToGroup } from '../../store/actions/groupActions'

const AddToGroup = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleClose = (value) => {
    setAnchorEl(null);
  }

  const handleMenuItemClick = (event, group) => {
    props.addSourceToGroup(group, props.source)
    setAnchorEl(null);

  }

  return (
    <div>
      <div className="" style={{ background: 'white', textAlign: 'center' }}>
        <Button variant='outlined' onClick={handleOpen} style={{ color: '#FF4500', borderColor: '#FF4500', width: '100%', marginTop: 5 }}>Add to group</Button>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.groups && props.groups.map((group, index) => (
          <MenuItem
            key={index}
            onClick={event => handleMenuItemClick(event, group)}
          >
            {group.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    groups: state.firestore.ordered.groups
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addSourceToGroup: (group, source) => dispatch(addSourceToGroup(group, source)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'groups', where: [['authorId', '==', (props.auth.uid ? props.auth.uid : '')]] }
  ]))(AddToGroup)
