import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { addContentBookmark } from '../../store/actions/contentActions'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'
import YouTubePlayer from 'react-player/lib/players/YouTube'
import ReactPlayer from 'react-player'
import ReactHtmlParser from 'react-html-parser';

import AddContent from './AddContent'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'

import qs from 'query-string'
import firebase from '../../config/fbConfig'
import { followSource, unfollowSource } from '../../store/actions/followActions'

import mail from '../../res/mail-line.svg'
import link from '../../res/link-line.svg'
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';


const ContentDetail = (props) => {
  console.log(props.location)
  // const [hasError, setError] = useState(false);
  const [content, setContent] = useState({});
  const [open, setOpen] = React.useState(false);
  const [snackMessage, setMessage] = React.useState('');


  useEffect(() => {
    console.log(content)
    if (Object.entries(content).length === 0) {
      if (!props.location.state) {
        let values = qs.parse(props.location.search)
        refreshData(values.sourceid, values.item)
      } else {
        if (props.location.state.parentSource) {

          setContent({
            ...props.location.state.content,
            sourceid: props.location.state.parentSource.id,
            type: props.location.state.parentSource.type
          })
        } else {

          refreshData(props.location.state.sourceid, props.location.state.title)
        }

      }
    }
  }, [content]);

  const refreshData = (id, title) => {
    console.log(id, title)
    const db = firebase.firestore()
    db.collection('sources').where('id', '==', parseInt(id, 10)).get()
      .then(snap => {
        if (!snap.empty) {
          let source = snap.docs[0].data()
          if (source && source.rss) {
            let rssItem = source.rss.items.filter(item => {
              return item.title === title
            })

            setContent({
              ...rssItem[0],
              sourceid: source.id,
              type: source.type
            })
          }
        } else {
          console.log('did not find')
        }
      }).catch(err => console.log(err))
  }

  const renderButtonValue = () => {
    if (props.following && props.following.length && content) {
      let following = props.following.filter(item => {
        return parseInt(item.id) === parseInt(content.sourceid)
      })
      if (following.length) {
        return "FOLLOWING"
      } else {
        return "FOLLOW"
      }
    } else {
      return "FOLLOW"
    }
  }

  const handleClickFollow = e => {
    if (e.target.textContent === 'FOLLOWING') {
      this.props.unfollowSource(this.state.parent)
    } else {
      this.props.followSource(this.state.parent)
    }
  };

  const handleOpen = (message) => {
    setMessage(message)
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const transform = (node, index) => {

    if (node.name === 'img') {
      console.log(node)
      return <img src={node.attribs.src} style={{ maxWidth: '80vw' }} key={index} alt="" />
    }
  }

  const options = {
    decodeEntities: true,
    transform
  }

  if (content) {
    console.log(content)

    const checkSourceType = () => {
      if (content.type === 'youtube') {
        return (
          <div className="">
            <YouTubePlayer
              url={content.link}
              controls={true}
              width="100%"
              height="60vh"
              style={{ maxWidth: 1024, backgroundColor: '#f5f5f5' }}
            />
          </div>
        )
      } else if (content.type === 'blog') {

        return (
          <div>{ReactHtmlParser(content['content:encoded'], options)}</div>
        )
      } else if (content.type === 'podcast') {

        if (content.link && content.link.includes('soundcloud')) {
          return (
            <div className="">
              <div>{ReactHtmlParser(content.content)}</div>
              <ReactPlayer
                url={content.link}
                controls={true}
                width="100%"
                height="100%"
                style={{ maxWidth: 1024 }}
              />
            </div>
          )
        } else
          return (
            <div className="">
              <div>{ReactHtmlParser(content['content:encoded'])}</div>
              <ReactPlayer
                url={content.enclosure.url}
                controls={true}
                width="100%"
                height={content.enclosure.url.includes('mp3') ? '10vh' : '100%'}
                style={{ maxWidth: 1024 }}
              />
            </div>
          )
      }
    }

    return (

      <Container maxWidth='md'>
        <div className="" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <AddContent content={content} />
          <Button onClick={() => {
            props.addContentBookmark(content)
            handleOpen('Bookmarked!')
          }}>
            {props.bookmarks && props.bookmarks.filter((item, index) => (item.id === content.id)).length === 1 ? <BookmarkBorderOutlinedIcon /> : <BookmarkOutlinedIcon />}
          </Button>
          <Button onClick={() => {
            window.location.href = `mailto:?subject=${encodeURIComponent('Check out what I found on Fizio!')}&body=${document.URL} ${encodeURIComponent('You can find the link here: ')} ${window.location.href}`
          }}><img src={mail} alt="" /></Button>
          <CopyToClipboard text={window.location.href}
            onCopy={() => handleOpen('Link Copied!')}>
            <Button><img src={link} alt="" /></Button>
          </CopyToClipboard>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{snackMessage}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </div>
        <h1 className="" style={{ fontWeight: 'bold' }}>{content.title}</h1>
        <div>
          <Link to={'/source/' + content.author}><p style={{ color: 'black' }}>{content.author}</p></Link>
          {props.auth.uid ?
            <Button value={renderButtonValue()} onClick={handleClickFollow} variant="outlined" style={{ width: 100, height: 40, border: '1px solid #FF4500', borderRadius: 4, color: '#FF4500', boxShadow: 'none', backgroundColor: 'white', fontSize: 12, marginTop: 20 }}>{renderButtonValue() ? renderButtonValue() : ''}</Button>
            :
            <div className=""></div>
          }
        </div>
        <h6 className="">{content.description}</h6>
        {checkSourceType()}
      </Container>
    )
  } else

    return (
      <div>LOADING</div>
    )
}

const mapStateToProps = (state) => {
  console.log(state)
  return {
    auth: state.firebase.auth,
    following: state.firestore.ordered.following,
    bookmarks: state.firestore.ordered.bookmarks
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addContentBookmark: (content) => dispatch(addContentBookmark(content)),
    followSource: (source) => dispatch(followSource(source)),
    unfollowSource: (source) => dispatch(unfollowSource(source))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'bookmarks/' + props.auth.uid + '/userBookmarks', storeAs: 'bookmarks' },
    { collection: 'following/' + props.auth.uid + '/userFollowing', storeAs: 'following' }
  ])
)(ContentDetail)
