import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import MainCard from '../main/MainCard';
import GroupCard from '../main/GroupCard'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';


const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

const PickList = props => {

  const classes = useStyles();

  const { picks, groups } = props;

  if (!picks && !groups) {
    return (
      <div style={{ width: '100%', height: 280, background: '#f8f8f8', display: 'flex' }}>
        {/* Placeholder */}
      </div>
    )
  } else {
    if (!groups) {
      if (window.innerWidth <= 650) {
        return (
          <div style={{ maxWidth: 400 }}>
            <GridList className={classes.gridList} cellHeight='auto' spacing={24}>
              <div></div>
              {picks &&
                picks.map((pick, index) => (
                  <GridListTile className="" key={index} cols={1.5}>
                    <MainCard pick={pick} id='link' key={index} />
                  </GridListTile>
                ))}
            </GridList>
          </div>
        );
      } else {
        return (
          <div className={classes.root}>
            <GridList className={classes.gridList} cellHeight='auto' spacing={24}>
              <div></div>
              {picks &&
                picks.map((pick, index) => (
                  <GridListTile className="" key={index} cols={.70}>
                    <MainCard pick={pick} id='link' key={index} />
                  </GridListTile>
                ))}
            </GridList>
          </div>
        );
      }
    } else {
      if (window.innerWidth <= 650) {
        return (
          <div style={{ maxWidth: 400, width: 400 }}>
            <GridList className={classes.gridList} cellHeight='auto' spacing={24}>
              <div></div>
              {groups &&
                groups.map((item, index) => (
                  <GridListTile className="" key={index} cols={1.5}>
                    <GroupCard group={item} id='link' key={index} />
                  </GridListTile>
                ))}
            </GridList>
          </div>
        );
      } else {
        return (
          <div className={classes.root}>
            <GridList className={classes.gridList} cellHeight='auto' spacing={24}>
              <div></div>
              {groups &&
                groups.map((item, index) => (
                  <GridListTile className="" key={index} cols={.70}>
                    <GroupCard group={item} id='link' key={index} />
                  </GridListTile>
                ))}
            </GridList>
          </div>
        );
      }
    }
  }








};

export default PickList;
