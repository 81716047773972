import React, { useState, useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'lightgrey',
      },
    },
  },
})(TextField);

const Searchbar = (props) => {

  const [searchText, setSearchText] = useState('')
  const [enter, setEnter] = useState(false)

  let history = useHistory()

  useEffect(() => {
    if (enter === true) {
      reloadSearchBar()
    }
  }, [enter])

  const reloadSearchBar = () => {
    setEnter(false)
    history.push({
      pathname: '/results/' + searchText,
      state: searchText
    })
  }

  const handleChange = e => {
    setSearchText(e.target.value)
  };


  return (
    <div className=''>
      <form action="" style={{ width: '100%', display: 'flex' }}>
        <CssTextField
          id="searchtext"
          value={searchText}
          type='text'
          placeholder="Search the world's fitness content"
          variant="outlined"
          style={styles.textFieldStyle}
          onChange={handleChange}
          autoComplete='off'
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            'aria-label': 'bare'
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              setEnter(true)
            }
          }}
        />
        <Button
          component={Link}
          to={{
            pathname: '/results/' + searchText,
            state: searchText
          }}
          disabled={!searchText}
          className='btn large'
          style={styles.buttonStyle}>
          Search
        </Button>
      </form>
    </div>
  );
}
const styles = {
  textFieldStyle: {
    width: window.innerWidth >= 690 ? '100%' : 'auto',
    height: '65px',
    borderRadius: '4px',
  },
  buttonStyle: {
    height: '55px',
    color: 'white',
    width: '110px',
    textAlign: 'center',
    background: '#FF4500',
    boxShadow: 'none',
    borderRadius: '4px',
    marginLeft: 5
  }
};

export default Searchbar;
