import React from 'react'
import Container from '@material-ui/core/Container'

const Terms = () => {

  React.useEffect(() => {
    window.scroll(0, 0)
  }, [true])

  return (
    <Container maxWidth='md'>
      <h2>Terms and Conditions</h2>
      <p>This Privacy Policy was last updated on July 1, 2019.
</p>
      <h3>Agreement between User and Fizio</h3>
      <p>
        Fizio, Inc. ("Fizio") provides its health and fitness content and services to
        you through its website(s), applications, and API and provides information about
        its services through its websites at www.fizio.co (the “Service”) subject to the
        following Terms of Use (as amended from time to time, the “Terms of Use”).For
        the purposes of these Terms of Use, the Fizio services, including any new
        features, the Services and the Apps, are referred to collectively as the
        “Service”.
</p>
      <p>
        Fizio is offered to you conditioned on your acceptance without modification of
        the terms, conditions, and notices contained herein (the "Terms"). Your use of
        Fizio constitutes your agreement to all such Terms. Please read these terms
        carefully, and keep a copy of them for your reference.
</p>
      <p>
        PLEASE READ THIS TERMS OF USE CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION
        REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THESE INCLUDE VARIOUS
        LIMITATIONS AND EXCLUSIONS, AND A DISPUTE RESOLUTION CLAUSE THAT GOVERNS HOW
        DISPUTES WILL BE RESOLVED.
</p>
      <h3>Privacy</h3>
      <p>
        Your use of Fizio is subject to Fizio's Privacy Policy. Please review our
        Privacy Policy, which also governs the Service and informs users of our data
        collection practices.
</p>
      <h3>Electronic Communications</h3>
      <p>
        Visiting Fizio or sending emails to Fizio constitutes electronic communications.
        You consent to receive electronic communications and you agree that all
        agreements, notices, disclosures and other communications that we provide to you
        electronically, via email and in the Services, satisfy any legal requirement
        that such communications be in writing.
</p>
      <h3>Your Account</h3>
      <p>
        If you use the Services, you are responsible for maintaining the confidentiality
        of your account and password and for restricting access to your computer, and
        you agree to accept responsibility for all activities that occur under your
        account or password. You may not assign or otherwise transfer your account to
        any other person or entity. You acknowledge that Fizio is not responsible for
        third party access to your account that results from theft or misappropriation
        of your account. Fizio and its associates reserve the right to refuse or cancel
        service, terminate accounts, or remove or edit content in our sole discretion.
</p>
      <h3>Access and Use of the Service</h3>
      <h4>Children Under Thirteen</h4>
      <p>
        Fizio does not knowingly collect, either online or offline, personal information
        from persons under the age of thirteen. If you are under 13 years of age, you
        are not authorized to use the Service, with or without registering. In addition,
        if you are under 18 years old, you may use the Service, with or without
        registering, only with the approval of your parent or guardian.
</p>
      <h4>Your Registration Obligations</h4>
      <p>
        You are required to register with Fizio to access and use the Service and
        certain features.If you choose to register for the Service, you agree to provide
        and maintain true, accurate, current and complete information about yourself as
        prompted by the Service’s registration form.Registration data and certain other
        information about you are protected and governed by our Privacy Policy.
</p>
      <h4>Member Account, Password and Security</h4>
      <p>
        You are responsible for maintaining the confidentiality of your password and
        account, if any, and are fully responsible for all activities that occur under
        your password or account. You agree to (a) immediately notify Fizio of any
        unauthorized use of your password or account or any other breach of security,
        and (b) ensure that you exit from your account at the end of each session when
        accessing the Service. Fizio will not be liable for any loss or damage arising
        from your failure to comply with this Section.
</p>
      <h4>Modifications to Service</h4>
      <p>
        Fizio reserves the right to modify or discontinue, temporarily or permanently,
        the Service (or any part thereof) with or without notice. You agree that Fizio
        shall not be liable to you or to any third party for any modification,
        suspension or discontinuance of the Service.
</p>
      <h4>General Practices Regarding Use and Storage</h4>
      <p>
        You acknowledge that Fizio may establish general practices and limits concerning
        use of the Service, including without limitation the maximum period that data or
        other content will be retained by the Service and the maximum storage space that
        will be allotted on Fizio’s servers on your behalf. You agree that Fizio has no
        responsibility or liability for the deletion or failure to store any data or
        other content maintained or transmitted by the Service. You acknowledge that
        Fizio reserves the right to terminate accounts that are inactive for an extended
        period. You further acknowledge that Fizio reserves the right to change these
        general practices and limits at any time, in its sole discretion, with or
        without notice.
</p>
      <h4>Third Party Platform Data Integration</h4>
      <p>
        As part of the Service, Fizio organizes and provides efficient means to access
        and receive updates regarding your contacts that may have been previously
        dispersed over many different third party email and calendar services such as
        Gmail and Google Calendar and other similar platforms, from which you authorize
        us to import your contacts, calendar entries and email (the “Third Party
        Platforms”).By authorizing us to important your information from a Third-Party
        Platform, you represent and warrant that you are the proper and authorized
        account holder with the applicable Third Party Platform and that you have the
        right to authorize use to receive the designated information from the applicable
        Third Party Platform.
</p>
      <h4>Mobile Services</h4>
      <p>
        The Service includes certain services that are available via a mobile device
        (collectively, the “Mobile Services”). To the extent, you access the Service
        through a mobile device, your wireless service carrier’s standard charges, data
        rates and other fees may apply.You should check with your carrier to find out
        what plans are available and how much they cost.In addition, downloading,
        installing, or using certain Mobile Services may be prohibited or restricted by
        your carrier, and not all Mobile Services may work with all carriers or
        devices.Therefore, you should check with your carrier to find out if the Mobile
        Services are available for your mobile device, and what restrictions, if any,
        may be applicable to your use of such Mobile Services.By using the Mobile
        Services, you agree that we may communicate with you regarding Fizio and other
        entities by SMS, MMS, text message or other electronic means to your mobile
        device and that certain information about your usage of the Mobile Services may
        be communicated to us.In the event, you change or deactivate your mobile
        telephone number, you agree to promptly update your Fizio account information to
        ensure that your messages are not sent to the person that acquires your old
        number.
</p>
      <h3>Conditions of Use</h3>
      <h4>User Conduct</h4>
      <p>
        You are solely responsible for all information, data, text, software, music,
        sound and/or other materials (“content”) that you upload, post, publish or
        display (hereinafter, “post”) or email or otherwise transmit or use via the
        Service.
</p>
      <h4>Fees</h4>
      <p>
        You acknowledge that Fizio reserves the right to charge for any portion of the
        Service and to change its fees (if any) from time to time in its discretion. If
        Fizio terminates your account and use of the Service because you have breached
        these Terms of Use, you shall not be entitled to the refund of any unused
        portion of fees or payments (if any).
</p>
      <h4>Special Notice for International Use; Export Controls</h4>
      <p>
        The Service is controlled, operated and administered by Fizio from our offices
        within the USA. If you access the Service from a location outside the USA, you
        are responsible for compliance with all local laws. You agree that you will not
        use the Fizio Content accessed through Fizio in any country or in any manner
        prohibited by any applicable laws, restrictions or regulations.
</p>
      <p>
        Software available about the Service and the transmission of applicable data, if
        any, is subject to United States export controls. No Software may be downloaded
        from the Service or otherwise exported or re-exported in violation of U.S.
        export laws. Downloading or using the Software is at your sole risk. Recognizing
        the global nature of the Internet, you agree to comply with all local rules
        regarding online conduct and acceptable content. Specifically, you agree to
        comply with all applicable laws regarding the transmission of technical data
        exported from the United States or the country in which you reside.
</p>
      <h4>No Resale of Service</h4>
      <p>
        Unless otherwise specified, you agree not to display, distribute, license,
        perform, publish, reproduce, duplicate, copy, create derivative works from,
        modify, sell, resell, exploit, transfer or transmit for any commercial purposes,
        any portion of the Service, use of the Service, or access to the Service.
</p>
      <h3>Cancellation/Refund Policy</h3>
      <p>
        This Agreement shall remain in full force and effect as long as it is present on
        any of the Services. You may terminate your membership at any time, for any
        reason, by following the instructions on the Services. If you cancel your
        membership before the end of the applicable billing cycle, your account will be
        cancelled as of the following month. You will not receive a refund for the
        current billing period.
</p>
      <h3>Links to Third Party Sites/Third Party Services</h3>
      <p>
        Fizio may contain links to other websites ("Linked Sites"). The Linked Sites are
        not under the control of Fizio and Fizio is not responsible for the contents of
        any Linked Site, including without limitation any link contained in a Linked
        Site, or any changes or updates to a Linked Site. Fizio is providing these links
        to you only as a convenience, and the inclusion of any link does not imply
        endorsement by Fizio of the site or any association with its operators.
</p>
      <p>
        Certain services made available via Fizio are delivered by third party sites and
        organizations. By using any product, service or functionality originating from
        the Fizio domain, you hereby acknowledge and consent that Fizio may share such
        information and data with any third party with whom Fizio has a contractual
        relationship to provide the requested product, service or functionality on
        behalf of Fizio users and customers.
</p>
      <h3>No Unlawful or Prohibited Use/Intellectual Property</h3>
      <p>
        You are granted a non-exclusive, non-transferable, revocable license to access
        and use Fizio strictly in accordance with these terms of use. As a condition of
        your use of the Service, you warrant to Fizio that you will not use the Service
        for any purpose that is unlawful or prohibited by these Terms. You may not use
        the Service in any manner which could damage, disable, overburden, or impair the
        Service or interfere with any other party's use and enjoyment of the Service.
        You may not obtain or attempt to obtain any materials or information through any
        means not intentionally made available or provided for through the Service.
</p>
      <p>
        All content included as part of the Service, such as text, graphics, logos,
        images, as well as the compilation thereof, and any software used on the
        Service, is the property of Fizio or its suppliers and protected by copyright
        and other laws that protect intellectual property and proprietary rights. You
        agree to observe and abide by all copyright and other proprietary notices,
        legends or other restrictions contained in any such content and will not make
        any changes thereto.
</p>
      <p>
        You will not modify, publish, transmit, reverse engineer, participate in the
        transfer or sale, create derivative works, or in any way exploit any of the
        content, in whole or in part, found on the Service. Fizio content is not for
        resale. Your use of the Service does not entitle you to make any unauthorized
        use of any protected content, and in particular you will not delete or alter any
        proprietary rights or attribution notices in any content. You will use protected
        content solely for your personal use, and will make no other use of the content
        without the express written permission of Fizio and the copyright owner. You
        agree that you do not acquire any ownership rights in any protected content. We
        do not grant you any licenses, express or implied, to the intellectual property
        of Fizio or our licensors except as expressly authorized by these Terms.
</p>
      <h3>Indemnification</h3>
      <p>
        You agree to indemnify, defend and hold harmless Fizio, its officers, directors,
        employees, agents and third parties, for any losses, costs, liabilities and
        expenses (including reasonable attorney's fees) relating to or arising out of
        your use of or inability to use the Services, any user postings made by you,
        your violation of any terms of this Agreement or your violation of any rights of
        a third party, or your violation of any applicable laws, rules or regulations.
        Fizio reserves the right, at its own cost, to assume the exclusive defense and
        control of any matter otherwise subject to indemnification by you, in which
        event you will fully cooperate with Fizio in asserting any available defenses.
</p>
      <h3>Arbitration</h3>
      <p>
        In the event the parties are not able to resolve any dispute between them
        arising out of or concerning these Terms and Conditions, or any provisions
        hereof, whether in contract, tort, or otherwise at law or in equity for damages
        or any other relief, then such dispute shall be resolved only by final and
        binding arbitration pursuant to the Federal Arbitration Act, conducted by a
        single neutral arbitrator and administered by the American Arbitration
        Association, or a similar arbitration service selected by the parties, in a
        location mutually agreed upon by the parties. The arbitrator's award shall be
        final, and judgment may be entered upon it in any court having jurisdiction. In
        the event that any legal or equitable action, proceeding or arbitration arises
        out of or concerns these Terms and Conditions, the prevailing party shall be
        entitled to recover its costs and reasonable attorney's fees. The parties agree
        to arbitrate all disputes and claims in regards to these Terms and Conditions or
        any disputes arising as a result of these Terms and Conditions, whether directly
        or indirectly, including Tort claims that are a result of these Terms and
        Conditions. The parties agree that the Federal Arbitration Act governs the
        interpretation and enforcement of this provision. The entire dispute, including
        the scope and enforceability of this arbitration provision shall be determined
        by the Arbitrator. This arbitration provision shall survive the termination of
        these Terms and Conditions.
</p>
      <h3>Class Action Waiver</h3>
      <p>
        Any arbitration under these Terms and Conditions will take place on an
        individual basis; class arbitrations and class/representative/collective actions
        are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE
        OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER
        IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN
        THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless
        both you and Fizio agree otherwise, the arbitrator may not consolidate more than
        one person's claims, and may not otherwise preside over any form of a
        representative or class proceeding.
</p>
      <h3>Liability Disclaimer</h3>
      <p>
        THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE
        THROUGH THE SERVICES MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES
        ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. FIZIO AND/OR ITS SUPPLIERS MAY
        MAKE IMPROVEMENTS AND/OR CHANGES IN THE SERVICES AT ANY TIME.
</p>
      <p>
        FIZIO AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY,
        RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION,
        SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SERVICES FOR
        ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
        INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS
        IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. FIZIO AND/OR ITS SUPPLIERS HEREBY
        DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION,
        SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED
        WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
        TITLE AND NON-INFRINGEMENT.
</p>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL FIZIO
        AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
        SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT
        LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY
        WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SERVICES, WITH THE DELAY OR
        INABILITY TO USE THE SERVICES OR RELATED SERVICES, THE PROVISION OF OR FAILURE
        TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
        RELATED GRAPHICS OBTAINED THROUGH THE SERVICES, OR OTHERWISE ARISING OUT OF THE
        USE OF THE SERVICES, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
        LIABILITY OR OTHERWISE, EVEN IF FIZIO OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED
        OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW
        THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
        DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH
        ANY PORTION OF THE SERVICES, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND
        EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICES.
</p>
      <p>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT FIZIO SHALL NOT BE LIABLE FOR ANY
        DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR
        DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
        GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF FIZIO HAS BEEN ADVISED
        OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT,
        NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM: (I) THE USE OR THE
        INABILITY TO USE THE SERVICE; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS
        AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED
        OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM
        THE SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR
        DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (V) ANY
        OTHER MATTER RELATING TO THE SERVICE. IN NO EVENT SHALL FIZIO’S TOTAL LIABILITY
        TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED ONE HUNDRED DOLLARS
        ($100).
</p>
      <h3>Disclaimer of Warranties</h3>
      <p>
        Your use of the service is at your sole risk. The service is provided on an “as
        is” and “as available” basis. Fizio expressly disclaims all warranties of any
        kind, whether express, implied or statutory, including, but not limited to the
        implied warranties of merchantability, fitness for a purpose, title and
        non-infringement.
</p>
      <p>
        Fizio makes no warranty that (i) the service will meet your requirements, (ii)
        the service will be uninterrupted, timely, secure, or error-free, (iii) the
        results that may be obtained from the use of the service will be accurate or
        reliable, (iv) the quality of any products, services, information, or other
        material purchased or obtained by you through the service will meet your
        expectations, and (v) any errors will be corrected.
</p>
      <p>
        Any material downloaded or otherwise obtained using the service is done at your
        own discretion and risk and that you will be solely responsible for any damage
        to your computer system or equipment or loss of data that results from the
        download or use of any such material. No information, whether oral or written,
        obtained by you from Fizio or through or from the service shall create any
        warranty not expressly stated in these terms of use.
</p>
      <p>
        Always use caution when giving out any personally identifying information about
        yourself or your children. Fizio does not control or endorse the content,
        messages or information found on the service and, therefore, Fizio specifically
        disclaims any liability about the service and any other actions resulting from
        your participation in the service.
</p>
      <h3>Termination/Access Restriction</h3>
      <p>
        Fizio reserves the right, in its sole discretion, to terminate your access to
        the Services and the related services or any portion thereof at any time,
        without notice. To the maximum extent permitted by law, this agreement is
        governed by the laws of the State of California and you hereby consent to the
        exclusive jurisdiction and venue of courts in California in all disputes arising
        out of or relating to the use of the Service. Use of the Service is unauthorized
        in any jurisdiction that does not give effect to all provisions of these Terms,
        including, without limitation, this section.
</p>
      <p>
        You agree that no joint venture, partnership, employment, or agency relationship
        exists between you and Fizio as a result of this agreement or use of the
        Service. Fizio's
</p>
      <p>
        performance of this agreement is subject to existing laws and legal process, and
        nothing contained in this agreement is in derogation of Fizio's right to comply
        with governmental, court and law enforcement requests or requirements relating
        to your use of the Service or information provided to or gathered by Fizio with
        respect to such use. If any part of this agreement is determined to be invalid
        or unenforceable pursuant to applicable law including, but not limited to, the
        warranty disclaimers and liability limitations set forth above, then the invalid
        or unenforceable provision will be deemed superseded by a valid, enforceable
        provision that most closely matches the intent of the original provision and the
        remainder of the agreement shall continue in effect.
</p>
      <p>
        Unless otherwise specified herein, this agreement constitutes the entire
        agreement between the user and Fizio with respect to the Service and it
        supersedes all prior or contemporaneous communications and proposals, whether
        electronic, oral or written, between the user and Fizio with respect to the
        Service. A printed version of this agreement and of any notice given in
        electronic form shall be admissible in judicial or administrative proceedings
        based upon or relating to this agreement to the same extent and subject to the
        same conditions as other business documents and records originally generated and
        maintained in printed form. It is the express wish to the parties that this
        agreement and all related documents be written in English.
</p>
      <h3>User Disputes</h3>
      <p>
        You agree that you are solely responsible for your interactions with any other
        user of the Service. Fizio reserves the right, but has no obligation, to become
        involved in any way with disputes between you and any other user of the Service.
</p>
      <h3>General</h3>
      <p>
        These Terms of Use constitute the entire agreement between you and Fizio and
        govern your use of the Service, superseding any prior agreements between you and
        Fizio with respect to the Service. You also may be subject to additional terms
        and conditions that may apply when you use affiliate services, third-party
        content or third-party software. These Terms of Use shall be governed by the
        laws of the State of California without regard to its conflict of law
        provisions. With respect to any disputes or claims not subject to arbitration,
        as set forth above, you and Fizio agree to submit to the personal and exclusive
        jurisdiction of the state and federal courts located within Los Angeles,
        California. Each of the parties hereby knowingly, voluntarily and intentionally
        waives any right it may have to a trial by jury in respect of any litigation
        (including but not limited to any claims, counterclaims, cross-claims, or third
        party claims) arising out of, under or about this agreement.Further, each party
        hereto certifies that no representative or agent of either party has
        represented, expressly or otherwise, that such party would not in the event of
        such litigation, seek to enforce this waiver of right to jury trial
        provision.Each of the parties acknowledges that this section is a material
        inducement for the other party entering this Terms of Use. The failure of Fizio
        to exercise or enforce any right or provision of these Terms of Use shall not
        constitute a waiver of such right or provision. If any provision of these Terms
        of Use is found by a court of competent jurisdiction to be invalid, the parties
        nevertheless agree that the court should endeavor to give effect to the parties’
        intentions as reflected in the provision, and the other provisions of these
        Terms of Use remain in full force and effect. You agree that regardless of any
        statute or law to the contrary, any claim or cause of action arising out of or
        related to use of the Service or these Terms of Use must be filed within one (1)
        year after such claim or cause of action arose or be forever barred. A printed
        version of this agreement and of any notice given in electronic form shall be
        admissible in judicial or administrative proceedings based upon or relating to
        this agreement to the same extent and subject to the same conditions as other
        business documents and records originally generated and maintained in printed
        form. The section titles in these Terms of Use are for convenience only and have
        no legal or contractual effect. Notices to you may be made via either email or
        regular mail. The Service may also provide notices to you of changes to these
        Terms of Use or other matters by displaying notices or links to notices
        generally on the Service.
</p>
      <h3>Changes to Terms</h3>
      <p>
        Fizio reserves the right, in its sole discretion, to change the Terms under
        which Fizio is offered. The most current version of the Terms will supersede all
        previous versions. Fizio encourages you to periodically review the Terms to stay
        informed of our updates.
</p>
      <h3>Contact Information</h3>
      <p>
        Fizio welcomes your questions or comments regarding this Statement of Terms and
        Conditions. Please contact us at info@fizio.co to report any violations of these
        Terms of Use or to pose any questions regarding this Terms of Use or the
        Service.
</p>
    </Container>
  )
}

export default Terms
