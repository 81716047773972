import React from 'react';
import { Link } from 'react-router-dom'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

const GroupCard = props => {
  console.log(props)
  
  const { group } = props;
  return (
    <Card className="" style={{ textAlign: 'left', background: '#Ff8f8f8' }}>
      <CardActionArea component={Link} to={{
        pathname: '/groups/query?groupid=' + group.id,
        state: group
      }}>
        <div className="" style={{
          backgroundImage: `url(${group.photoUrl})`,
          height: '280px',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          textAlign: 'left',
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'column',
          padding: 10,
        }}>
          <h2 style={{ color: 'white', margin: 0 }}>{group.name}</h2>
          <p style={{ color: 'white', margin: 0, fontWeight: 'bold' }}>{group.description}</p>
        </div>
      </CardActionArea>
    </Card>
  );
};

export default GroupCard;
