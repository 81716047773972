export const createCollection = (collection) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;

    const defaultImage = 'https://source.unsplash.com/400x400/?fitness,health'
    
    var newCollectionRef = firestore.collection('collections').doc();

    if (collection.picture instanceof File) {
      var file = collection.picture
      var metadata = {
        contentType: collection.picture.type
      }
      var uploadImageTask = firebase.storage().ref()
        .child(`collections/${new Date().getTime()}` + file.name).put(file, metadata)

      uploadImageTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(progress)
        },
        (error) => {
          console.log(error.code)
        },
        () => {
          console.log('complete')
          uploadImageTask.snapshot.ref.getDownloadURL().then(url => {
            newCollectionRef.update({
              photoUrl: url
            })
          })
        })
    }

    newCollectionRef.set({
      name: collection.name,
      description: collection.description,
      private: collection.private && collection.private === true ? collection.private : false,
      photoUrl: defaultImage,
      authorFirstName: profile.firstName,
      authorLastName: profile.lastName,
      authorId: authorId,
      authorDisplayName: profile.displayName,
      authorProfileUrl: profile.photoUrl,
      createdAt: new Date()
    }).then(() => {
      dispatch({ type: 'CREATE_COLLECTION', collection: collection })
    }).catch((err) => {
      dispatch({ type: 'CREATE_COLLECTION_ERROR', err })
    })
  }
}

export const deleteCollection = (collection) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => { }
}

export const updateCollection = (collection) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => { }
}

export const addSource = (collection, source) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore.collection('collections').doc(collection.id).update({
      content: firestore.FieldValue.arrayUnion(source),
    }).then(() => {
      dispatch({ type: 'SOURCE_ADDED', source: source })
    }).catch((err) => {
      dispatch({ type: 'SOURCE_ADDED_ERROR', err })
    })
  }
}

export const removeSource = (collection, source) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore.collection('collections').doc(collection.id).update({
      content: firestore.FieldValue.arrayRemove(source),
    }).then(() => {
      dispatch({ type: 'SOURCE_REMOVED', source: source })
    }).catch((err) => {
      dispatch({ type: 'SOURCE_REMOVED_ERROR', err })
    })
  }
}