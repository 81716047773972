import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import { removeContentBookmark } from '../../store/actions/contentActions'



const Bookmarks = (props) => {

  const { bookmarks } = props
  console.log(bookmarks)

  if (bookmarks) {
    return (
      <div>
        <Container maxWidth='md'>
          <h1>Bookmarks</h1>
          {bookmarks && bookmarks.map((item, index) => {
            return (
              <div key={index}>
                <Button disabled={true}><BookmarkBorderOutlinedIcon /></Button>
                <Link
                  to={{
                    pathname: "/content/source?sourceid=" + item.sourceid + "&item=" + encodeURIComponent(item.title),
                    state: item
                  }}
                  style={{ color: 'black' }}
                >{item.title} {item.author}</Link>
                <Button onClick={() => props.removeContentBookmark(item)}><ClearRoundedIcon /></Button>
              </div>
            )
          })}
        </Container>
      </div>
    )
  } else {
    if (bookmarks && bookmarks.length === 0) {
      return (
        <div>
          0 Bookmarks
        </div>
      )
    } else {
      return (
        <div className='' style={{ margin: 'auto', textAlign: 'center' }}>
          <CircularProgress style={{ color: '#FF4500' }} />
        </div>
      )
    }

  }
}

const mapStateToProps = (state) => {
  console.log(state)
  return {
    auth: state.firebase.auth,
    bookmarks: state.firestore.ordered.bookmarks
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeContentBookmark: (content) => dispatch(removeContentBookmark(content))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'bookmarks/' + props.auth.uid + '/userBookmarks', storeAs: 'bookmarks' }
  ])
)(Bookmarks)