export const followSource = (source) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore();
    const uid = getState().firebase.auth.uid;

    var ref = firestore.collection('following/' + uid + "/userFollowing").doc(source.id.toString());
    ref.set({
      ...source
    }).then(() => {
      dispatch({ type: 'FOLLOW', source: source })
    }).catch((err) => {
      dispatch({ type: 'FOLLOW_ERROR', err })
    })
  }
}

export const unfollowSource = (source) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore();
    const uid = getState().firebase.auth.uid;

    var ref = firestore.collection('following/' + uid + "/userFollowing").doc(source.id.toString());
    ref.delete().then(() => {
      dispatch({ type: 'UNFOLLOW', source: source })
    }).catch((err) => {
      dispatch({ type: 'UNFOLLOW_ERROR', err })
    })
  }
}