import React, { Component } from 'react'
import { connect } from 'react-redux'
import { forgotPassword } from '../../store/actions/authActions'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'lightgrey',
      },
    },
  },
})(TextField);

class Forgot extends Component {
  state = {
    email: ''
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.forgotPassword(this.state.email)
  }
  render() {
    const { authError } = this.props;
    return (
      <div className="" style={{ backgroundColor: "#F7F7F7", width: "100%", height: "100vh", marginTop: "-20px" }}>
        <div className="container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: "60vh", flexDirection: 'column' }}>
          <div className="" style={{
            width: '320px',
            height: '390px',
            border: "1px solid grey",
            marginTop: '20px',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <div className="" style={{ width: "90%" }}>
              <form onSubmit={this.handleSubmit} className="white">
                <div className="" style={{ textAlign: 'center' }}>
                  <p>Trouble Logging In?</p>
                  <span>Enter your username or email and we'll send<br />you a link to get back into your account.</span>
                </div>
                <div className="" style={{ marginTop: 20 }}>
                  <p>Email</p>
                  <CssTextField variant='outlined' className='browser-default' type="email" id="email" onChange={this.handleChange} style={styles.textFieldStyle} autoComplete="email" placeholder='Email' required />
                </div>
                <div className="" style={{ marginTop: 20 }}>
                  <Button onClick={this.handleSubmit} style={{ backgroundColor: "#FF4500", width: "100%", height: "48px", color: 'white' }}>SEND LINK</Button>
                </div>
                <div className="" >
                  <div className="red-text center" style={{ marginTop: 20 }}>
                    <Link to="/signin" style={{ color: "#FF4500" }}>Back to Login</Link>
                  </div>
                </div>
              </form>
              <div className="red-text center">
                {authError ? <p>{authError}</p> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  textFieldStyle: {
    borderRadius: '4px',
    width: '100%',
    height: '48px',
  }
}
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (email) => dispatch(forgotPassword(email))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Forgot)

