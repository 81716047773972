import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import CircularProgress from '@material-ui/core/CircularProgress';


import SourceList from '../source/SourceList'

const Feed = (props) => {
  console.log(props)

  useEffect(() => {

  }, []);

  if (props.following && props.following.length) {
    return (
      <div className="">
        <SourceList sources={props.following} />
      </div>
    )
  } else {
    return (
      <div className='' style={{ margin: 'auto', textAlign: 'center' }}>
        <CircularProgress style={{ color: '#FF4500' }} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  
  return {
    auth: state.firebase.auth,
    following: state.firestore.ordered.following
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'following/' + props.auth.uid + '/userFollowing', storeAs: 'following' }
  ])
)(Feed)
