const initState = {
  bookmarks: {

  },
  bookmarkError: null
}

const contentReducer = (state = initState, action) => {
  switch (action.type) {
    case 'BOOKMARK':
      console.log('bookmark success')
      return state;
    case 'BOOKMARK_ERROR':
      console.log('bookmark error', action.err)
      return {
        ...state,
        bookmarkError: action.err.message
      };
    case 'BOOKMARK_DELETE_SUCCESS':
      console.log('bookmark delete success')
      return state;
    case 'BOOKMARK_DELETE_ERROR':
      console.log('bookmark delete error', action.err)
      return {
        ...state,
        bookmarkError: action.err.message
      };
    default:
      return state
  }
}

export default contentReducer