import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { addSource, removeSource } from '../../store/actions/collectionActions'

const AddContent = (props) => {

  console.log(props)
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false)
  const [collectionRef, setCollectionRef] = useState({})

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleClose = (value) => {
    setAnchorEl(null);
  }

  const handleMenuItemClick = (event, collection) => {

    props.addSource(collection, {content: props.content})
    setAnchorEl(null);
    setOpen(true)
    setCollectionRef(collection)
  }

  const handleUndo = () => {

    props.removeSource(collectionRef, {content: props.content})
    setOpen(false)
  }

  return (
    <div>
      <div className="" style={{ background: 'white', textAlign: 'center' }}>
        <Button onClick={handleOpen} variant="outlined" style={{ width: 150, height: 40, border: '1px solid #FF4500', borderRadius: 4, color: '#FF4500', boxShadow: 'none', backgroundColor: 'white', fontSize: 12, margin: 20 }}>+ Collection</Button>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.collections && props.collections.map((collection, index) => (
          <MenuItem
            key={index}
            onClick={event => handleMenuItemClick(event, collection)}
          >
            {collection.name}
          </MenuItem>
        ))}
      </Menu>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Added to Collection!</span>}
        action={[
          <Button key="undo" color="secondary" size="small" onClick={handleUndo}>
            UNDO
          </Button>,
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>

        ]}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    collections: state.firestore.ordered.collections
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addSource: (collection, source) => dispatch(addSource(collection, source)),
    removeSource: (collection, source) => dispatch(removeSource(collection, source)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'collections', where: [['authorId', '==', (props.auth.uid ? props.auth.uid : '')]] }
  ]))(AddContent)
