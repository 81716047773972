import React from 'react'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Switch from '@material-ui/core/Switch';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { connect } from 'react-redux'
import { createGroup } from '../../store/actions/groupActions'

import TextField from '@material-ui/core/TextField';

const placeholder = 'https://source.unsplash.com/400x400/?fitness,health'

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'lightgrey',
      },
    },
  },
})(TextField);

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const SimpleDialog = (props) => {
  const { onClose, open } = props;
  const classes = useStyles();
  const [collection, setCollection] = React.useState({
    name: '',
    description: '',
    picture: placeholder
  });

  const CoverPhoto = (props) => {
    const { pictureUrl, placeholder } = props;
    if (!pictureUrl) {
      return (
        <div style={{
          height: '200px',
          width: '200px',
          borderWidth: '.1rem',
          borderStyle: 'solid', borderColor: 'lightgrey',
        }} className="text-center  cropped-upper">
          <img className="" alt='' width='100%' height='100%' src={placeholder} mode='cover' />
        </div>
      );
    }
    else {
      return (
        <div style={{
          height: '200px',
          width: '200px',
          borderWidth: '.1rem',
          borderStyle: 'solid', borderColor: 'lightgrey',
          overflow: 'hidden'
        }} className="text-center  cropped-upper">
          <img className="" alt='' width='100%' height='100%' src={pictureUrl} mode='cover' />
        </div>
      );
    }
  };

  const handleClose = () => {
    onClose(collection);
  }

  const handleCancel = () => {
    onClose();
  }

  const handleChange = (e) => {
    const id = e.target.id
    const val = e.target.value
    setCollection(prev => {
      return {
        ...prev,
        [id]: val
      }
    })
  }

  const handleFileSelection = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setCollection(prev => {
        return {
          ...prev,
          picture: file,
          pictureUrl: reader.result
        }
      })
    };
    reader.readAsDataURL(file);

  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={'md'}>
      <DialogTitle id="form-dialog-title">Create New Group</DialogTitle>
      <form action='' noValidate style={{ padding: '20px' }}>
        <div className="input-field">
          <p>Group Name</p>
          <CssTextField variant='outlined' className="browser-default" placeholder='e.g. Workouts' type="text" id="name" onChange={handleChange} style={styles.textFieldStyle} required maxLength="50" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
        </div>
        <div className="input-field">
          <p>Description</p>
          <CssTextField variant='outlined' className="browser-default" placeholder='Describe your collection' type="text" id="description" onChange={handleChange} style={styles.textFieldStyle} required maxLength="100" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
        </div>
        <p>Cover Photo (Optional)</p>
        <p><em>Random image picker provided by Unsplash.com</em></p>
        <div className='section'>
          <div className=''>
            <CoverPhoto
              picture={collection.picture}
              pictureUrl={collection.pictureUrl}
              placeholder={placeholder}
            />
          </div>
          <div className="">
            <Button className='' style={{ width: 200, height: 48, backgroundColor: '#FF4500', color: 'white' }}>
              <input
                accept="image/*"
                type='file'
                id='photoUrl'
                onChange={handleFileSelection}
                style={{
                  opacity: 0,
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer'
                }}
              />
              CHOOSE A FILE
              </Button>
          </div>
        </div>
        <div className="" style={{ display: 'flex', marginTop: 20 }}>
          <div className="input-field">
            <Button className="" style={{ background: "#FF4500", color: 'white' }} onClick={handleClose}>Create</Button>
          </div>
          <div className="input-field">
            <Button className="" style={{ background: 'white', color: 'grey' }} onClick={handleCancel}>Cancel</Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
}

const styles = {
  textFieldStyle: {
    borderRadius: '4px',
    width: '100%',
    height: '48px',
  }
}

const CreateGroup = (props) => {
  const [open, setOpen] = React.useState(false);


  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    if (value.name && value.name.length) {
      props.createGroup(value)
    }
    setOpen(false)
  }



  return (
    <div data-tut=".seventh-step">
      <div className="" style={{ background: 'white', textAlign: 'center' }}>
        <Button onClick={handleOpen} style={{ color: 'grey' }}>Create Group</Button>
      </div>
      <SimpleDialog className="" open={open} onClose={handleClose} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    createGroup: (group) => dispatch(createGroup(group)),
  }
}

export default connect(null, mapDispatchToProps)(CreateGroup)
