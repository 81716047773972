import React, { useState, useEffect } from 'react'
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateProfile } from '../../store/actions/authActions';
import PreviewProfilePicture from '../auth/PreviewProfilePicture';
import NewFooter from '../footer/NewFooter';
import Container from '@material-ui/core/Container'

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash'
import { update } from 'immutable';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'lightgrey',
      },
    },
  },
})(TextField);

const placeholder = require('../../res/placeholder-profile.svg');


const Account = (props) => {
  const [form, setValues] = useState({})

  const { authError, profile } = props;

  useEffect(() => {

  }, [])

  const checkProfile = () => {
    if (Object.keys(form).length === 0) {
      setValues({
        ...profile
      })
    } else {
      return
    }
  }

  const handleChange = e => {
    e.preventDefault()
    console.log(e.target.id, e.target.value)
    setValues({
      ...form,
      [e.target.id]: e.target.value
    })
  };
  const handleSubmit = e => {
    e.preventDefault()
    if (_.isEqual(form, profile)){
      return
    }else{
      props.updateProfile(form)
    }
  };
  const handleFileSelection = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setValues({
        ...form,
        picture: file,
        photoUrl: reader.result
      })
    };
    reader.readAsDataURL(file);
  };

  if (profile.isLoaded === true) {
    checkProfile()
  }

  return (
    <Container maxWidth='md'>

      <h4 style={{ fontWeight: 'bold' }}>Account Preview</h4>
      <form onSubmit={handleSubmit} autoComplete='none' noValidate>
        <p>Profile Photo</p>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex' }}>
            <div className=''>
              <PreviewProfilePicture
                pictureUrl={form.photoUrl}
                placeholder={placeholder}
              />
            </div>
          </div>
          <div style={{ display: 'flex', margin: '50px' }}>
            <Button variant='outlined' className='' style={{ width: 200, height: 36, backgroundColor: 'white', color: '#FF4500', borderColor: '#FF4500' }}>
              <input
                accept="image/*"
                type='file'
                id='photoUrl'
                onChange={handleFileSelection}
                style={{
                  opacity: 0,
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer'
                }}
              />
              CHANGE PHOTO
              </Button>
          </div>
        </div>
        <div className='s6 m12' style={{ display: 'flex' }}>
          <div className='' style={{ flex: 1 }}>
            <p>First Name</p>
            <CssTextField
              type='text'
              id='firstName'
              required
              style={styles.textFieldStyle}
              onChange={handleChange}
              variant='outlined'
              autoComplete="firstName"
              value={form.firstName ? form.firstName : ''}
            />
          </div>
          <div className='' style={{ width: 20 }}></div>
          <div className='input-field' style={{ flex: 1 }}>
            <p>Last Name</p>
            <CssTextField
              type='text'
              id='lastName'
              required
              style={styles.textFieldStyle}
              onChange={handleChange}
              variant='outlined'
              autoComplete="lastName"
              value={form.lastName ? form.lastName : ''}
            />
          </div>
        </div>
        <div className='input-field'>
          <p>Email Address</p>
          <CssTextField
            disabled={true}
            type='email'
            id='email'
            required
            style={styles.textFieldStyle}
            onChange={handleChange}
            variant='outlined'
            autoComplete="email"
            value={form.email ? form.email : ''}
          />
        </div>
        <div className='input-field'>
          <p>Username</p>
          <CssTextField
            type='text'
            id='displayName'
            required
            style={styles.textFieldStyle}
            onChange={handleChange}
            variant='outlined'
            autoComplete="userName"
            value={form.displayName ? form.displayName : ''}
          />
        </div>
        <div className='input-field'>
          <p>Password</p>
          <Button variant='outlined' component={Link} to={'/forgot'} style={{ color: '#FF4500' }}>Change Password</Button>
        </div>
        <div
          className=''
          style={{
            height: 220,
            borderRadius: 8,
            paddingTop: 20,
            display: 'auto'
          }}>
          <div className=''>
            <div className='input-field'>
              <Button
                variant='outlined'
                disabled={_.isEqual(form, profile)}
                onClick={handleSubmit}
              >
                Update Account
                </Button>
            </div>
            <div className="" style={{ marginTop: 20 }}>
              <span style={{ fontStyle: 'italic' }}>
                See our{' '}
                <Link to='/terms' style={{ color: '#FF4500' }}>
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link to='/privacy' style={{ color: '#FF4500' }}>
                  Privacy Policy
                </Link>
              </span>
            </div>
          </div>
        </div>
      </form>
      <div className=''>
        {authError ? <p>{authError}</p> : null}
      </div>
      <NewFooter />
    </Container>
  )
}

const styles = {
  textFieldStyle: {
    borderRadius: '4px',
    width: '100%',
    height: '48px',
  }
};

const mapStateToProps = state => {

  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateProfile: user => dispatch(updateProfile(user))

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);
