import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';


const SignedOutLinks = () => {
  return (
    <div className="" style={{ display: 'flex' }}>
      {/* <Button component={Link} to={'/signup'} style={styles.primaryButton}>Sign Up</Button> */}
      <Button component={Link} to={'/signin'} style={styles.secondaryButton}>Log In</Button>
    </div>
  );
}

const styles = {
  primaryButton: {
    color: 'white', 
    background: '#FF4500',
    borderRadius: 4,
  },
  secondaryButton: {
    color: '#FF4500', 
    background: 'transparent', 
    border: '1px solid #FF4500', 
    borderRadius: 4,
    marginLeft: 5
  }
}

export default SignedOutLinks