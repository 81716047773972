import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

const bg = require('../../res/mathiasjensen.png')
const phone = require('../../res/william-hook.png')
const member = require('../../res/woman-water.png')
const product = require('../../res/fitness-health.png')


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Subscription = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [true])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <div className=""
        style={{
          backgroundImage: `url(${bg})`,
          minHeight: '50vh',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '50vh'
        }}>
        <h1 style={{ paddingTop: '30px' }}>Become A Member</h1>
        <p>Your support will help us grow.</p>
      </div>
      <Container maxWidth='md'>
        <h3>Fizio Plus members recieve exclusive benefits including discounts on partner products, branded merch, and member-only newsletters and Zero ads on mobile & web apps.</h3>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <img src={phone} alt="" style={{ height: 160, width: 220 }} />
            <h3>Go Ad Free</h3>
            <p>Enjoy an ad-free experience on Fizio mobile and web apps.</p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <img src={member} alt="" style={{ height: 160, width: 220 }} />
            <h3>Access to Tools and Dashboards</h3>
            <p>Get access to newsletters, including personalized recommnedations in your inbox and notifications.</p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <img src={product} alt="" style={{ height: 160, width: 220 }} />
            <h3>Get Fizio Branded Stuff</h3>
            <p>Get discounts on Fizio branded shirts, water bottles and gear.</p>
          </Grid>
        </Grid>
      </Container>
      <h1>Join Fizio Plus</h1>
      <Container maxWidth='md' className={classes.root}>
        <Tabs value={value} onChange={handleChange} aria-label='simple tabs' centered>
          <Tab label='Basic' {...a11yProps(0)} />
          <Tab label='Monthly' {...a11yProps(1)} />
          <Tab label='Annual' {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <h3>BASIC</h3>
          <h1>Free</h1>
          <Button variant='outlined'>Create Free Account</Button>
          <p>Already a subscriber? Log in</p>
          <Divider />
          <p>Read and Bookmark across desktop, mobile web and apps.</p>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <h3>SUPPORTER</h3>
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'flex-start' }}>
            <h1>$5.99</h1><p style={{ paddingTop: 20 }}>/monthly</p>
          </div>
          <Button variant='outlined'>Support Fizio monthly</Button>
          <p>Already a supporter? Log in</p>
          <p>Enjoy an ad-free app experience</p>
          <Divider />
          <p>Get Discounts on Fizio Stuff</p>
          <Divider />
          <p>Members-only newsletters</p>
          <Divider />
          <p>Save and read bookmarks across desktop, mobile web and apps.</p>
          <Divider />
          <p>Customize your newsletter and notifications</p>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <h3>SUPER SUPPORTER</h3>
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'flex-start' }}>
            <h1>$99.99</h1><p style={{ paddingTop: 20 }}>/year</p>
          </div>
          <Button variant='outlined'>Support Fizio Yearly</Button>
          <p>Already a Super Supporter? Log in</p>
          <p>Enjoy an ad-free app experience</p>
          <Divider />
          <p>Get Discounts on Fizio Stuff</p>
          <Divider />
          <p>Members-only newsletters</p>
          <Divider />
          <p>Save and read bookmarks across desktop, mobile web and apps.</p>
          <Divider />
          <p>Customize your newsletter and notifications</p>
          <Divider />
          <p>Exclusive gift: T-Shirt & Water Bottle</p>
        </TabPanel>
      </Container>
    </div >
  )
}

export default Subscription
