import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { followSource, unfollowSource } from '../../store/actions/followActions'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Linker from '@material-ui/icons/Link';
import clsx from 'clsx';



import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import AddToGroup from '../groups/AddToGroup'


const podcast = require('../../res/icon-audio.svg');
const blog = require('../../res/icon-text.svg');
const youtube = require('../../res/icon-video.svg');

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.1em',
    fontWeight: 500
  },
  subheader: {
    fontSize: '1em'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const SourceCard = props => {
  // console.log(props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleOpenSnack() {
    setOpen(true);
  }

  function handleCloseSnack(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }


  const renderButtonValue = () => {
    if (props.following && props.following.length && props.source) {
      let following = props.following.filter(item => {
        return parseInt(item.id) === parseInt(props.source.id)
      })
      if (following.length) {
        return "FOLLOWING"
      } else {
        return "FOLLOW"
      }
    } else {
      return "FOLLOW"
    }
  }

  const handleClickFollow = e => {
    if (e.target.textContent === 'FOLLOWING') {
      props.unfollowSource(props.source)
    } else {
      props.followSource(props.source)
    }
    handleOpenSnack()
  };

  const checkIconType = () => {
    if (props.type === 'podcast') {
      return podcast;
    } else if (props.type === 'blog') {
      return blog;
    } else if (props.type === 'youtube') {
      return youtube;
    }
  };

  const checkType = () => {
    if (props.type === 'podcast') {
      return "PODCAST SERIES";
    } else if (props.type === 'blog') {
      return "BLOG";
    } else if (props.type === 'youtube') {
      return "YOUTUBE CHANNEL";
    }
  };

  return (
    <Card
      className=''
      style={{
        minHeight: '280px',
        margin: 5,
      }}>
      <CardActionArea
        component={Link}
        to={{
          pathname: '/source/' + encodeURI(props.source.titleClean),
          state: {
            source: props.source,
          }
        }}
      >
        <CardMedia
          style={{ paddingTop: '100%', backgroundSize: 'contain' }}
          image={props.source.imageURL}
        />
      </CardActionArea>
      <CardHeader
        classes={{
          title: classes.title,
          subheader: classes.subheader
        }}
        title={forceUnicodeEncoding(props.source.titleClean.substring(0, 35))}
      />
      <CardContent>
        <div className="" style={{ display: 'flex' }}>
          <img
            src={checkIconType()}
            alt=''
            width={20}
            style={{ marginRight: 10 }}
          />
          {checkType()}
        </div>
      </CardContent>
      <CardActions>
        {props.auth.uid ?
          <div style={{width: '100%'}}>
            <Button value={renderButtonValue()} onClick={handleClickFollow} variant='outlined' style={{ color: '#FF4500', borderColor: '#FF4500', width: '100%' }}>{renderButtonValue() ? renderButtonValue() : ''}</Button>
            <AddToGroup source={props.source} />
          </div>
          :
          <div></div>
        }
      </CardActions>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={1000}
        onClose={handleCloseSnack}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id"> {props.source.titleClean} </span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleCloseSnack}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Card>
  );
};

const forceUnicodeEncoding = (string) => {
  return decodeURIComponent(escape(string));

}

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    auth: state.firebase.auth,
    following: state.firestore.ordered.following
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    followSource: (source) => dispatch(followSource(source)),
    unfollowSource: (source) => dispatch(unfollowSource(source))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'following/' + props.auth.uid + '/userFollowing', storeAs: 'following' }
  ])
)(SourceCard)