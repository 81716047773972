import React from 'react'

const NewFooter = () => {
  return (
    <footer>
      <div className="container">

        <div>
          <ul>
            <li>@2019 FIZIO, INC.</li>
          </ul>
        </div>

      </div>
    </footer>
  )
}
export default NewFooter
