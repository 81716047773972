import React from 'react'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

const Footer = () => {
  return (
    <Container maxWidth='md'>
      <div style={{ textAlign: 'left', flexDirection: 'column', padding: 20 }}>
        <div style={{ display: 'flex' }}>
          <p>Follow Us</p>
          <a href='https://www.facebook.com/fizio-2274062076185134/' style={{ color: 'black' }}><IconButton><FacebookIcon style={{ color: '#FF4500' }} /></IconButton></a>
          <a href='http://www.instagram.com/wearefizio' style={{ color: 'black' }}><IconButton><InstagramIcon style={{ color: '#FF4500' }} /></IconButton></a>
          <a href='http://www.twitter.com/wearefizio' style={{ color: 'black' }}><IconButton><TwitterIcon style={{ color: '#FF4500' }} /></IconButton></a>
        </div>
        <p><Link style={{ color: 'black', fontSize: '10' }} to="/FAQ" className="grey-text">FAQ</Link></p>
        {/* <p><Link style={{ color: 'black', fontSize: '10' }} to="/" className="grey-text">About Us</Link></p> */}
        <p><Link style={{ color: 'black', fontSize: '10' }} to="/contactus" className="grey-text">Contact Us</Link></p>
        <p><Link style={{ color: 'black', fontSize: '10' }} to="/terms" className="black-text">Terms of Use</Link></p>
        <p><Link style={{ color: 'black', fontSize: '10' }} to="/privacy" className="black-text">Privacy Policy</Link></p>
        <p>Copyright 2019 Fizio</p>
      </div>
    </Container>
  )
}
export default Footer
