import React from 'react'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core/Hidden';


const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'lightgrey',
      },
    },
  },
})(TextField);

const SimpleDialog = (props) => {
  const { onClose, open } = props;
  const [collection, setCollection] = React.useState({
    name: '',
    description: ''
  });

  const handleClose = () => {
    onClose(collection);
  }

  const handleChange = (e) => {
    const id = e.target.id
    const val = e.target.value
    setCollection(prev => {
      return {
        ...prev,
        [id]: val
      }
    })
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={'md'}>
      <DialogTitle id="form-dialog-title">Suggest a new source Fizio.co</DialogTitle>
      <DialogTitle>Are we missing something you'd like to see on Fizio?</DialogTitle>
      <form action='' noValidate style={{ padding: '20px' }}>
        <p>Our goal is to be the most comprehensive fitness resource in the world. Fill in the fields below to let us know if we're missing something important!</p>
        <div className="">
          <p>Description</p>
          <CssTextField variant='outlined' className="browser-default" placeholder='Describe the content source; i.e. youtube, blog, podcast,' type="text" id="name" onChange={handleChange} style={styles.textFieldStyle} required maxLength="50" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} autoComplete="off" />
        </div>
        <div className="">
          <p>Website Link</p>
          <CssTextField variant='outlined' className="browser-default" placeholder='http://yourlink.here.com' type="text" id="website" onChange={handleChange} style={styles.textFieldStyle} required maxLength="100" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} autoComplete="off" />
        </div>
        <div className="" style={{ display: 'flex', marginTop: 20 }}>
          <div className="">
            <Button className="" style={{ background: "#FF4500", color: 'white', height: '48px' }} onClick={handleClose}>Submit</Button>
          </div>
          <div className="">
            <Button className="" style={{ background: 'white', color: 'grey', height: '48px' }} onClick={handleClose}>Cancel</Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
}

const styles = {
  textFieldStyle: {
    borderRadius: '4px',
    width: '100%',
    height: '48px',
  }
}

const AddSource = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = (value) => {
    sendMessage(value)
    setOpen(false)
  }
  const sendMessage = (value) => {

    fetch('https://cors-anywhere.herokuapp.com/https://api.sendgrid.com/v3/mail/send', {
      method: 'POST',
      headers: {
        "Authorization": "Bearer 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",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
      body: JSON.stringify({
        "personalizations": [
          {
            "to": [
              {
                "email": "joe@fizio.co",
                "name": "David Fizio"
              }
            ],
            "dynamic_template_data": {
              "verb": "",
              "adjective": "",
              "noun": "",
              "currentDayofWeek": "",
              "subject": "New Source Suggestion",
              "name": value.name,
              "website": value.website
            },
          }
        ],
        "from": {
          "email": props.auth.email,
          "name": props.auth.displayName
        },
        "subject": "",
        "content": [{
          "type": "text/plain",
          "value": value.name + value.website
        }],
        // "reply_to": {
        //   "email": "noreply@fizio.co",
        //   "name": "John Doe"
        // },
        "template_id": "d-cbcd0aded03b4b1fa407156007b6dfb7"
      })
    }).then(response => {
      console.log(response)
    }).catch(err => console.log(err))

  }

  return (
    <div>
      <Hidden smDown>
        <Button onClick={handleOpen} variant="outlined" style={{ height: 40, border: '1px solid #FF4500', borderRadius: 4, color: '#FF4500', boxShadow: 'none', backgroundColor: 'white', fontSize: 12, marginRight: 20, marginLeft: 20 }}>SUGGEST SOURCE</Button>
      </Hidden>
      <SimpleDialog className="" open={open} onClose={handleClose} />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //createCollection: (collection) => dispatch(createCollection(collection)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSource)
