const stepsLoggedOut = [
  {
    selector: '[data-tut=".first-step"]',
    content: "Search the world's fitness content on one platform.  Find the most popular fitness content, all in one place.",
    position: 'bottom'
  },
  {
    selector: '[data-tut=".second-step"]',
    content: "Hand-picked collections of workouts and expert advice, from our curators. Think of playlists for mood, life changes, or a program to do the fly. As a user, you can curate and make your own collections."
  },
  {
    selector: '[data-tut=".third-step-loggedout"]',
    content: "Sign up or Login to customize your Fizio experience"
  },
]

const stepsLoggedIn = [
  {
    selector: '[data-tut=".first-step"]',
    content: "Search the world's fitness content"
  },
  {
    selector: '[data-tut=".second-step"]',
    content: "Hand-picked collections of workouts and expert advice, from our curators. Think of playlists for mood, life changes, or a program to do the fly. As a user, you can curate and make your own collections."
  },
  {
    selector: '[data-tut=".third-step"]',
    content: "Click here to customize your Fizio"
  },
  {
    selector: '[data-tut=".fourth-step"]',
    content: "Follow your favorite sources and bookmark the content you love"
  },
  {
    selector: '[data-tut=".fifth-step"]',
    content: "Bookmark the content you love"
  },
  {
    selector: '[data-tut=".sixth-step"]',
    content: "Access collections you've saved or created"
  },
  {
    selector: '[data-tut=".seventh-step"]',
    content: "Click here to create your first collection"
  },
  {
    selector: '[data-tut=".eigth-step"]',
    content: "Thats all for now! Stay here to create your first collection or close out to explore Fizio. Well done and enjoy!"
  }
]

export default { stepsLoggedIn, stepsLoggedOut }
