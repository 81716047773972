const initState = {
  collections: {

  }
}

const collectionReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_COLLECTION':
      console.log('create collection success')
      return state;
    case 'CREATE_COLLECTION_ERROR':
      console.log('create project error', action.err)
      return state;
    case 'SOURCE_ADDED':
      console.log('create collection success')
      return state;
    case 'SOURCE_ADDED_ERROR':
      console.log('source added error', action.err)
      return state;
    case 'SOURCE_REMOVED':
      console.log('removed source success')
      return state;
    case 'SOURCE_REMOVED_ERROR':
      console.log('source removed error', action.err)
      return state;
    default:
      return state
  }
}

export default collectionReducer