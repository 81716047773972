import React from 'react'
import PickList from '../main/PickList'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

const ProfileDetail = (props) => {
  console.log(props)
  const profile = props.user
  return (
    <div className="">
      {profile && profile ?
        <Container className="" maxWidth='md'>
          <div className="" style={{ display: 'flex' }}>
            <div className="" style={{ margin: 20 }}>
              <img src={profile.photoUrl} width='50px' alt="" />
            </div>
            <div className="">
              <h4 className="" style={{ fontWeight: '500' }}>{profile.firstName} {profile.lastName}</h4>
              <h5 className="">{profile.displayName}</h5>
            </div>
          </div>
          <h5>Collections</h5>
          <div className="" style={{ maxWidth: '100%', marginTop: 10 }}>
            <PickList picks={props.userCollections} />
          </div>
        </Container>

        :
        <div className='' style={{ margin: 'auto', textAlign: 'center' }}>
          <CircularProgress style={{ color: '#FF4500' }} />
        </div>
      }
    </div>
  )
}
const mapStateToProps = (state, ownProps) => {
  const user = state.firestore.ordered.users

  return {
    user: user && user[0],
    userCollections: state.firestore.ordered.userCollections
  }
}
export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      { collection: 'users', where: [['displayName', '==', props.match.params.handle]] },
      { collection: 'collections', where: [['authorDisplayName', '==', props.match.params.handle]], storeAs: 'userCollections' }
    ]
  })
)(ProfileDetail)