import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { Redirect, Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'lightgrey',
      },
    },
  },
})(TextField);

const FizioCheckbox = withStyles({
  root: {
    color: '#FF4500',
    '&$checked': {
      color: '#FF4500',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export class SignIn extends Component {
  state = {
    email: '',
    password: '',
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state)
  }
  render() {
    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to='/' />
    return (
      <div className="" style={{ backgroundColor: "white", width: "100%", height: "100vh" }}>
        <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <div className="" style={{
            width: '320px',
            height: '390px',
            border: "1px solid lightgrey",
            marginTop: '20px',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <div className="" style={{ width: "90%" }}>
              <form onSubmit={this.handleSubmit} className="white">
                <div className="input-field">
                  <p>Email</p>
                  <CssTextField className='browser-default' type="email" id="email" onChange={this.handleChange} style={styles.textFieldStyle} variant='outlined' autoComplete="email" placeholder='Email' required />
                </div>
                <div className="input-field">
                  <p>Password</p>
                  <CssTextField className='browser-default' type="password" id="password" onChange={this.handleChange} style={styles.textFieldStyle} variant='outlined' autoComplete="password" placeholder='Password' required />
                </div>
                <FormGroup row style={{marginTop: 10}}>
                  <FormControlLabel
                    control={
                      <FizioCheckbox
                        checked={this.state.checked}
                        onChange={this.handleChange}
                        value="true"
                        id='remember'
                        name='remember'
                      />
                    }
                    label="Keep me signed in"
                  />
                </FormGroup>
                <div className="input-field">
                  <Button onClick={this.handleSubmit} className="" style={{ backgroundColor: "#FF4500", width: "100%", height: "48px", color: 'white' }}>Login</Button>
                </div>
                <div className="input-field" style={{marginTop: 10}}>
                  <div className="red-text center">
                    <Link to="/forgot" style={{ color: "#FF4500" }}>Forgot Password?</Link>
                  </div>
                </div>
              </form>
              <div className="red-text center">
                {authError ? alert(authError) : null}
              </div>
            </div>
          </div>
          <div className="" style={{
            width: '320px',
            height: '60px',
            border: "1px solid lightgrey",
            marginTop: '20px',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <div className="input-field">
              <div className="">
                <span>Don't have an account? <Link to="/signup" style={{ color: "#FF4500" }}>Sign up</Link></span>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}
const styles = {
  textFieldStyle: {
    borderRadius: '4px',
    width: '100%',
    height: '48px',
  }
}
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
