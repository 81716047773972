import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import Main from './components/main/Main'
import Footer from './components/footer/Footer'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <CssBaseline />
          <Main />
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}
export default App;
