import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import Container from '@material-ui/core/Container';
import { removeSource } from '../../store/actions/collectionActions'

import qs from 'query-string'
import firebase from '../../config/fbConfig'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CircularProgress from '@material-ui/core/CircularProgress';


const podcast = require('../../res/icon-audio.svg');
const blog = require('../../res/icon-text.svg');
const youtube = require('../../res/icon-video.svg');



const CollectionDetail = (props) => {
  // const [collection, setCollection] = useState({});
  const [open, setOpen] = React.useState(false);

  const collection = props.collectionDetail && props.collectionDetail[0]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [true])

  const onCopy = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleRemove = (source) => {
    props.removeSource(collection, source)
  }

  if (collection) {

    const checkIconType = (type) => {
      if (type === 'podcast') {
        return podcast;
      } else if (type === 'blog') {
        return blog;
      } else if (type === 'youtube') {
        return youtube;
      }
    };

    return (
      <div>
        <div style={{ background: '#f8f8f8' }}>
          <Container maxWidth='md' style={{ display: 'flex' }}>
            <div className="" style={{ marginTop: 20 }}>
              <img src={collection.photoUrl} alt="" width={200} />
            </div>
            <div style={{ margin: 20 }}>
              <div style={{ display: 'flex' }}>
                <h1 className="" style={{ fontWeight: 'bold', margin: 0, fontSize: 34 }}>{collection.name}</h1>
                <CopyToClipboard text={window.location.href}
                  onCopy={onCopy}>
                  <Button><SendIcon /></Button>
                </CopyToClipboard>
              </div>
              <h6 className="" style={{ margin: 0 }}>{collection.description}</h6>
              <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: 12, color: 'black' }}>Collection curated by:</p>
              <div className="" style={{ display: 'flex' }}>
                <div className="" style={{ marginTop: 20 }}>
                  <img src={collection.authorProfileUrl} width='60px' alt="" style={{ overflow: 'hidden', borderRadius: 15, background: 'whites' }} />
                </div>
                <Link to={'/profile/' + collection.authorDisplayName} style={{ margin: 0 }}>
                  <p className="" style={{ fontWeight: 'bold', fontSize: 24, color: 'black', marginLeft: 20 }}> {collection.authorDisplayName}</p>
                </Link>
              </div>
            </div>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">Link Copied!</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          </Container>
        </div>
        <Container maxWidth='md'>
          {collection.content && collection.content.map((source, index) => {
            console.log(source)
            return (
              <div className="" key={index}>
                <div style={{ display: 'flex' }}>
                  <img
                    src={checkIconType(source.content.type)}
                    alt=''
                    width={20}
                    style={{ marginLeft: 10, paddingTop: 0, minWidth: 20 }}
                  />
                  <Link to={{
                    pathname: "/content/source?sourceid=" + source.content.sourceid + "&item=" + encodeURIComponent(source.content.title),
                    state: source.content
                  }} style={{ margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 20, color: 'black', flexDirection: 'column' }}>
                    <h3 className="">{source.content.title}</h3>
                  </Link>
                  {props.auth && collection.authorId === props.auth.uid ?
                    <Button onClick={() => handleRemove(source)}><ClearRoundedIcon /></Button>
                    :
                    <div></div>
                  }
                </div>
              </div>
            )
          }
          )
          }
        </Container>
      </div>
    )

  } else {
    return (
      <div className='' style={{ margin: 'auto', textAlign: 'center' }}>
        <CircularProgress style={{ color: '#FF4500' }} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log(state)
  return {
    auth: state.firebase.auth,
    collectionDetail: state.firestore.ordered.collectionDetail
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeSource: (collection, content) => dispatch(removeSource(collection, content))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'collections', storeAs: 'collectionDetail', where: [['name', '==', props.location.state.name ? props.location.state.name : '']] }
  ])
)(CollectionDetail)

