import React from 'react'
import Select from 'react-select';
import { connect } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'

import TextField from '@material-ui/core/TextField';


const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'lightgrey',
      },
    },
  },
})(TextField);

const ContactUs = (props) => {

  const handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    sendMessage()
  }

  const sendMessage = () => {

    fetch('https://cors-anywhere.herokuapp.com/https://api.sendgrid.com/v3/mail/send', {
      method: 'POST',
      headers: {
        "Authorization": "Bearer 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",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
      body: JSON.stringify({
        "personalizations": [
          {
            "to": [
              {
                "email": "joe@fizio.co",
                "name": "David Fizio"
              }
            ],
            "dynamic_template_data": {
              "verb": "",
              "adjective": "",
              "noun": "",
              "currentDayofWeek": "",
              "subject": "Thank you for contacting us!",
              "message": "Thanks from the Fizio team.",
              "topic": "Pretty Sweet."

            },
          }
        ],
        "from": {
          "email": "wearefizio@fizio.co",
          "name": "Fizio Team"
        },
        "reply_to": {
          "email": "noreply@fizio.co",
          "name": "John Doe"
        },
        "template_id": "d-aa13e0a5ceb64a13aec818890e74cea5"
      })
    }).then(response => {
      console.log(response)
    }).catch(err => console.log(err))

  }
  return (
    <Container maxWidth='md' style={{ flexDirection: "column", display: 'flex' }} className="section">
      <div className="container">
        <h3 style={{ color: '#FF4500' }}>Connect with Fizio</h3>
        <span style={{ color: '#FF4500' }}>Need some inspiration, encouragement, or assistance?</span><br />
        <span style={{ color: '#FF4500' }}>We’re here to help so let us know how we can help keep you moving.</span>
      </div>
      <div style={{ marginTop: 20 }} className="container">
        <form action="" className="">
          <div className="input-field">
            <p>Your name</p>
            <CssTextField variant='outlined' placeholder='Your name' type="text" id="toName" onChange={handleChange} style={styles.textFieldStyle} />
          </div>
          <div className="input-field">
            <p>Your email</p>
            <CssTextField variant='outlined' placeholder='Your email address' type="text" id="toEmail" onChange={handleChange} style={styles.textFieldStyle} />
          </div>
          <div className="input-field">
            <p>Subject</p>
            <CssTextField variant='outlined' placeholder='Subject' type="text" id="subject" onChange={handleChange} style={styles.textFieldStyle} />
          </div>
          <div className="input-field">
            <p>Message</p>
            <CssTextField variant='outlined' placeholder='Tell us more here' type="textarea" id="message" onChange={handleChange} style={styles.textFieldStyle} height="40vh" />
          </div>
          <div className="input-field" style={{ marginTop: 20, marginBottom: 20 }}>
            <Button variant='outlined' style={{ borderColor: '#FF4500', background: 'white', color: '#FF4500' }} onClick={handleSubmit} >Submit</Button>
          </div>
        </form>
      </div>
    </Container>
  )
}


const styles = {
  textFieldStyle: {
    width: '100%',
  }
}
const mapStateToProps = (state) => {
  console.log(state)
  return {

  }
}
const mapDispatchToProps = (dispatch) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)
