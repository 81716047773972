import React from 'react';
import SourceCard from '../source/SourceCard';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const SourceList = props => {
  const classes = useStyles();
  const { sources } = props;
  return (
    <div className={classes.root}>
      <Grid container spacing={1} className=''>
        {sources &&
          sources.map((item, index) => {
            return (
              <Grid item xs={4} style={{width: '100%'}} key={index}>
                <SourceCard key={index} source={item} type={item.type} />
              </Grid>
            )
          })}
      </Grid>
    </div>
  );
};

export default SourceList;
