const initState = {
  groups: {

  }
}

const groupReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_GROUP_SUCCESS':
      console.log('create group success')
      return state;
    case 'CREATE_GROUP_ERROR':
      console.log('create group error', action.err)
      return state;
    case 'SOURCE_ADDED_TO_GROUP':
      console.log('create group success')
      return state;
    case 'SOURCE_ADDED_TO_GROUP_ERROR':
      console.log('source added error', action.err)
      return state;
    case 'SOURCE_REMOVED_FROM_GROUP':
      console.log('removed source success')
      return state;
    case 'SOURCE_REMOVED_FROM_GROUP_ERROR':
      console.log('source removed error', action.err)
      return state;
    default:
      return state
  }
}

export default groupReducer